import React from 'react'
import { ScrollView, Text, TextInput, View } from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Layout, Colors, Buttons, TextStyles, Icons } from '../constants'
import { Consumer } from '../context/Context'
import { WindowResizeConsumer } from '../context/WindowResizeContext'
import Button from './Button'
import FormIcon from './FormIcon'

export default class RequestInfo extends React.Component {
    state = {
        fullNameFocused: false,
        emailFocused: false,
    }

    // componentDidMount = async () => {
    //     this.windowContext.setPreventResize()
    // }

    renderInputFullName = ({ sectionTitle, fullName, fullNameValid }) => {
        return (
            <View
                style={{
                    width: '100%',
                    paddingBottom: 48,
                }}
            >
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline,
                        {
                            flex: 1,
                            paddingBottom: 16,
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderBottomColor: this.state.fullNameFocused
                            ? Colors.tintColor
                            : Colors.disabled,
                        borderBottomWidth: 1,
                    }}
                >
                    <TextInput
                        keyboardAppearance={'dark'}
                        style={[
                            TextStyles.Paragraph,
                            {
                                width: '100%',
                                height: 48,
                                color: Colors.primaryText,
                                outlineWidth: 0,
                                outline: 'none',
                                // borderBottomColor: this.state.fullNameFocused
                                //     ? Colors.tintColor
                                //     : Colors.disabled,
                                //     borderBottomWidth: 1,

                                paddingBottom: 16,
                            },
                        ]}
                        value={fullName}
                        placeholder="Full name"
                        placeholderTextColor={Colors.disabled}
                        selectionColor={Colors.tintColor}
                        onChangeText={async (text) => {
                            await this.context.setFullName(text)
                            this.context.validateFullName(text)
                        }}
                        onFocus={() => {
                            this.setState({
                                fullNameFocused: true,
                                emailFocused: false,
                            })
                        }}
                        onEndEditing={(text) => {
                            this.context.validateFullName(text)
                        }}
                        maxLength={56}
                        keyboardType="default"
                        autoCapitalize="words"
                        returnKeyType="next"
                        blurOnSubmit={true}
                        autoCorrect={false}
                        autoFocus={true}
                    />
                    <FormIcon
                        iconName={'check'}
                        iconSize={Icons.small}
                        disabled={!fullName.length || !fullNameValid}
                    />
                </View>
            </View>
        )
    }

    renderInputEmail = ({ sectionTitle, emailNew, emailNewValid }) => {
        return (
            <View
                style={{
                    width: '100%',
                    paddingBottom: 48,
                }}
            >
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline,
                        {
                            flex: 1,
                            paddingBottom: 16,
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderBottomColor: this.state.emailFocused
                            ? Colors.tintColor
                            : Colors.disabled,
                        borderBottomWidth: 1,
                    }}
                >
                    <TextInput
                        keyboardAppearance={'dark'}
                        style={[
                            TextStyles.Paragraph,
                            {
                                width: '100%',
                                height: 48,
                                color: Colors.primaryText,
                                outlineWidth: 0,
                                outline: 'none',
                                paddingVertical: 16,
                                // borderBottomColor: this.state.emailFocused
                                //     ? Colors.tintColor
                                //     : Colors.disabled,
                                //     borderBottomWidth: 1,
                            },
                        ]}
                        value={emailNew}
                        placeholder="email@example.com"
                        placeholderTextColor={Colors.disabled}
                        selectionColor={Colors.tintColor}
                        onChangeText={async (text) => {
                            await this.context.setEmailNew(text)
                            this.context.validateEmailNew(text)
                        }}
                        onFocus={() => {
                            this.setState({
                                fullNameFocused: false,
                                emailFocused: true,
                            })
                        }}
                        onEndEditing={(text) => {
                            this.context.validateEmailNew(text)
                        }}
                        maxLength={64}
                        keyboardType="email-address"
                        autoCapitalize="none"
                        returnKeyType="next"
                        blurOnSubmit={true}
                        autoCorrect={false}
                    />
                    <FormIcon
                        iconName={'check'}
                        iconSize={Icons.small}
                        disabled={!emailNew.length || !emailNewValid}
                    />
                </View>
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Caption,
                        {
                            flex: 1,
                            paddingVertical: 16,
                            color:
                                emailNew.length && !emailNewValid
                                    ? Colors.accentRed
                                    : Colors.disabled,
                        },
                    ]}
                >
                    Email must be formatted correctly
                </Text>
            </View>
        )
    }

    render() {
        const handlePress = this.props.handlePress

        return (
            <WindowResizeConsumer>
                {(windowContext) => (
                    <View
                        style={{ flex: 1 }}
                        ref={(ref) => {
                            this.windowContext = windowContext
                        }}
                    >
                        <Consumer>
                            {(context) => {
                                return (
                                    <View
                                        style={{
                                            flex: 1,
                                        }}
                                        ref={(ref) => {
                                            this.context = context
                                        }}
                                    >
                                        <View style={Layout.sectionContainer}>
                                            <View
                                                style={[
                                                    Layout.columnOne,
                                                    {
                                                        maxWidth: 720,
                                                        paddingVertical:
                                                            Layout.topNavHeightDesktop,
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            paddingBottom:
                                                                Layout.paddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    Submit your email to get
                                                    updates about our journey.
                                                </Text>
                                                {/* {this.renderInputFullName({
                                                        sectionTitle:
                                                            'Your name',
                                                        fullName:
                                                            context.fullName,
                                                        fullNameValid:
                                                            context.fullNameValid,
                                                    })} */}
                                                {this.renderInputEmail({
                                                    sectionTitle: 'Email',
                                                    emailNew: context.emailNew,
                                                    emailNewValid:
                                                        context.emailNewValid,
                                                })}

                                                <View
                                                    style={{
                                                        // paddingTop:
                                                        //     Layout.paddingLarge,
                                                        flexDirection:
                                                            Layout.isMediumDevice
                                                                ? 'column-reverse'
                                                                : 'row',
                                                        alignSelf:
                                                            Layout.isMediumDevice
                                                                ? 'center'
                                                                : 'flex-end',
                                                        // alignSelf: 'center',
                                                    }}
                                                >
                                                    <Button
                                                        onPress={handlePress}
                                                        buttonStyle={[
                                                            Buttons.buttonUnderline,
                                                            {
                                                                marginVertical:
                                                                    Layout.isMediumDevice
                                                                        ? Layout.paddingMedium
                                                                        : null,
                                                                marginHorizontal:
                                                                    Layout.isMediumDevice
                                                                        ? null
                                                                        : Layout.paddingLarge,
                                                            },
                                                        ]}
                                                        textColor={
                                                            Colors.tintColor
                                                        }
                                                        textStyle={{
                                                            textDecorationLine:
                                                                'underline',
                                                        }}
                                                        title={'Close'}
                                                    />
                                                    <Button
                                                        buttonStyle={[
                                                            // Buttons.button,
                                                            {
                                                                alignSelf:
                                                                    'center',
                                                                minWidth: 160,
                                                            },
                                                        ]}
                                                        title={'Sign up'}
                                                        disabled={
                                                            !context.emailNewValid
                                                        }
                                                        onPress={async () => {
                                                            await this.context.onSubmitRequestForm()
                                                            this.context.setRequestConfirmed()
                                                            // this.props.navigation.navigate(
                                                            //     'PublicStack',
                                                            //     {
                                                            //         screen: 'RequestConfirmation',
                                                            //     }
                                                            // )
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                )
                            }}
                        </Consumer>
                    </View>
                )}
            </WindowResizeConsumer>
        )
    }
}
