import React from 'react'
import { ActivityIndicator, StyleSheet, View, Text } from 'react-native'
import { Colors, TextStyles, Layout } from '../constants'
import { Consumer } from '../context/Context'

export default function AuthLoading() {
    return (
        <Consumer>
            {(value) => {
                return (
                    <View style={styles.loadingContainer}>
                        <ActivityIndicator
                            size="small"
                            color={Colors.tintColor}
                        />

                        <Text
                            numberOfLines={1}
                            style={[
                                TextStyles.LogotypeLarge,
                                {
                                    alignSelf: 'center',
                                    paddingTop: Layout.paddingMedium,
                                },
                            ]}
                        >
                            Morphosis
                        </Text>
                    </View>
                )
            }}
        </Consumer>
    )
}

const styles = StyleSheet.create({
    loadingContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: Colors.black,
    },
})
