import React from 'react'
import { Platform, Image, Text, View } from 'react-native'
import * as Linking from 'expo-linking'
import { Colors, TextStyles, Layout, Buttons, Icons } from '../constants'
import ButtonIcon from './ButtonIcon'

export default class TeamAttributes extends React.Component {
    renderSeparator = () => {
        return (
            <View
                style={{
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: Layout.paddingMedium,
                    marginBottom: Layout.paddingMedium,
                }}
            />
        )
    }

    render() {
        return (
            <View
                style={[
                    Layout.projectContainer,
                    /*                     { backgroundColor: Colors.gray0 },
                     */
                ]}
            >
                <View style={[Layout.basicContainer, { alignSelf: 'center' }]}>
                    <Text
                        style={[
                            TextStyles.Heading1,
                            {
                                textAlign: 'center',
                                paddingBottom: Layout.paddingMedium,
                            },
                        ]}
                    >
                        The Team
                    </Text>
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                textAlign: 'center',
                            },
                        ]}
                    >
                        We’re a collective of seasoned craftspeople, bridging
                        the worlds of Food, Fashion and Technoglogy.
                    </Text>
                </View>
                <View
                    style={[
                        Layout.columnContainer,
                        {
                            alignItems: 'center',
                            maxWidth: 1440,
                        },
                    ]}
                >
                    <View
                        style={[Layout.columnThree, { alignItems: 'center' }]}
                    >
                        <View style={Layout.iconImageTeamCircle}>
                            <Image
                                source={require('../assets/images/memoji-dc.png')}
                                style={{
                                    width: 100,
                                    height: 100,
                                }}
                                resizeMode={'cover'}
                            />
                        </View>

                        <Text
                            style={[
                                TextStyles.Heading3,
                                {
                                    paddingBottom: Layout.paddingSmall,
                                    textAlign: 'center',
                                },
                            ]}
                        >
                            Dominique Crenn
                        </Text>
                        <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    lineHeight:
                                        TextStyles.ListParagraphLineHeight,
                                },
                            ]}
                        >
                            Visionary
                        </Text>
                        <ButtonIcon
                            onPress={() => {
                                if (Platform.OS == 'web') {
                                    window.open(
                                        'https://twitter.com/dominiquecrenn',
                                        '_blank'
                                    )
                                } else {
                                    Linking.openURL(
                                        'https://twitter.com/dominiquecrenn'
                                    ) // normal Linking react-native
                                }
                            }}
                            iconName={'twitter'}
                            iconColor={Colors.secondaryIcon}
                            buttonStyle={Buttons.menuButtonTransparentBG}
                        />
                    </View>
                    <View
                        style={[Layout.columnThree, { alignItems: 'center' }]}
                    >
                        <View style={Layout.iconImageTeamCircle}>
                            <Image
                                source={require('../assets/images/memoji-robens.png')}
                                style={{
                                    width: 100,
                                    height: 100,
                                }}
                                resizeMode={'cover'}
                            />
                        </View>

                        <Text
                            style={[
                                TextStyles.Heading3,
                                {
                                    paddingBottom: Layout.paddingSmall,
                                    textAlign: 'center',
                                },
                            ]}
                        >
                            Robens Elusme
                        </Text>
                        <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    lineHeight:
                                        TextStyles.ListParagraphLineHeight,
                                },
                            ]}
                        >
                            Business Strategist
                        </Text>
                        <ButtonIcon
                            onPress={() => {
                                if (Platform.OS == 'web') {
                                    window.open(
                                        'https://twitter.com/Elusmer',
                                        '_blank'
                                    )
                                } else {
                                    Linking.openURL(
                                        'https://twitter.com/Elusmer'
                                    ) // normal Linking react-native
                                }
                            }}
                            iconName={'twitter'}
                            iconColor={Colors.secondaryIcon}
                            buttonStyle={Buttons.menuButtonTransparentBG}
                        />
                    </View>
                    <View
                        style={[Layout.columnThree, { alignItems: 'center' }]}
                    >
                        <View style={Layout.iconImageTeamCircle}>
                            <Image
                                source={require('../assets/images/maurice-memoji-cropped.png')}
                                style={{
                                    width: 100,
                                    height: 100,
                                }}
                                resizeMode={'cover'}
                            />
                        </View>

                        <Text
                            style={[
                                TextStyles.Heading3,
                                {
                                    paddingBottom: Layout.paddingSmall,
                                    textAlign: 'center',
                                },
                            ]}
                        >
                            Maurice Kenji Clarke
                        </Text>
                        <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    lineHeight:
                                        TextStyles.ListParagraphLineHeight,
                                },
                            ]}
                        >
                            Creative Technologist
                        </Text>
                        <ButtonIcon
                            onPress={() => {
                                if (Platform.OS == 'web') {
                                    window.open(
                                        'https://twitter.com/MauriceKenji',
                                        '_blank'
                                    )
                                } else {
                                    Linking.openURL(
                                        'https://twitter.com/MauriceKenji'
                                    ) // normal Linking react-native
                                }
                            }}
                            iconName={'twitter'}
                            iconColor={Colors.secondaryIcon}
                            buttonStyle={Buttons.menuButtonTransparentBG}
                        />
                    </View>

                    <View
                        style={[Layout.columnThree, { alignItems: 'center' }]}
                    >
                        <View style={Layout.iconImageTeamCircle}>
                            <Image
                                source={require('../assets/images/memoji-vick.png')}
                                style={{
                                    width: 100,
                                    height: 100,
                                }}
                                resizeMode={'cover'}
                            />
                        </View>
                        <Text
                            style={[
                                TextStyles.Heading3,
                                {
                                    paddingBottom: Layout.paddingSmall,
                                    textAlign: 'center',
                                },
                            ]}
                        >
                            Vick Weerasak
                        </Text>
                        <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    lineHeight:
                                        TextStyles.ListParagraphLineHeight,
                                },
                            ]}
                        >
                            Fashion Designer
                        </Text>
                        <ButtonIcon
                            onPress={() => {
                                if (Platform.OS == 'web') {
                                    window.open(
                                        'https://twitter.com/VickWeerasak',
                                        '_blank'
                                    )
                                } else {
                                    Linking.openURL(
                                        'https://twitter.com/VickWeerasak'
                                    ) // normal Linking react-native
                                }
                            }}
                            iconName={'twitter'}
                            iconColor={Colors.secondaryIcon}
                            buttonStyle={Buttons.menuButtonTransparentBG}
                        />
                    </View>
                </View>
            </View>
        )
    }
}
