import React from 'react'
import { Image, View, Text } from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Colors, TextStyles, Layout, Icons } from '../constants'

export default class MetaWakeUp extends React.Component {
    renderSeparator = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.lightBackground,
                    paddingTop: Layout.paddingMedium,
                    marginBottom: Layout.paddingMedium,
                }}
            />
        )
    }

    render() {
        return (
            <View style={Layout.projectContainer}>
                <View
                    style={[
                        Layout.columnOne,
                        {
                            paddingHorizontal: Layout.isMediumDevice
                                ? 0
                                : Layout.paddingLarge,
                        },
                    ]}
                >
                    <View
                        style={[
                            Layout.cardContent,
                            {
                                // borderColor: Colors.gray0,
                                // borderWidth: 1,
                                backgroundColor: Colors.accentYellow,
                                maxWidth: 720,
                            },
                        ]}
                    >
                        <View
                            style={[
                                Layout.columnContainer,
                                { justifyContent: 'flex-start' },
                            ]}
                        >
                            <Image
                                source={require('../assets/images/dominique-crenn-AD-topbanner.jpg')}
                                style={[
                                    Layout.avatarImageCircle,
                                    { marginRight: Layout.paddingMedium },
                                ]}
                                resizeMode={'cover'}
                            />

                            <View
                                style={{
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                    //padding: Layout.paddingMedium,
                                }}
                            >
                                <Text style={TextStyles.ParagraphBold}>
                                    Dominique Crenn
                                </Text>
                                <Text style={TextStyles.Overline}>
                                    Human. Woman. Activist
                                </Text>
                            </View>
                        </View>
                        <Text
                            style={[
                                TextStyles.Heading3,
                                {
                                    paddingVertical: Layout.paddingLarge,
                                },
                            ]}
                        >
                            Dear Food Industry: This is your WAKE-UP CALL. I’m
                            calling on everyone in this industry that we love so
                            much—chefs, farmers, winemakers, journalists,
                            restaurant owners, influencers, food lovers,
                            servers, dishwashers, sommeliers, bakers, bloggers,
                            EVERYONE—to share this post and write ONE THING in
                            the caption that they are going to start doing today
                            to fight the climate emergency. My promise is this:
                            I am going to strive to make all my restaurants
                            waste-free—I've already started. Join me in sounding
                            the alarm. WAKEUP!
                        </Text>

                        {this.renderSeparator()}
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Feather
                                style={{
                                    alignSelf: 'center',
                                    paddingRight: Layout.paddingMedium,
                                }}
                                color={Colors.Icon}
                                size={Icons.small}
                                name={'instagram'}
                            />
                            <Feather
                                style={{
                                    alignSelf: 'center',
                                    paddingRight: Layout.paddingMedium,
                                }}
                                color={Colors.Icon}
                                size={Icons.small}
                                name={'twitter'}
                            />
                            <Text style={TextStyles.Paragraph}>
                                #incrennible{'  '}#planet{'  '}#humanity
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
}
