import { Colors } from '../constants'

const experiential = 'U.S. Experiential Economy: $1.5T'

const marketSize = {
    nodes: [
        {
            id: experiential,
            color: 'hsl(310, 70%, 50%)',
        },
        {
            id: 'Food services and drinking places: $860B',
            color: 'hsl(5, 70%, 50%)',
        },
        {
            id: 'Arts, entertainment, recreation: $363B',
            color: 'hsl(128, 70%, 50%)',
        },
        {
            id: 'Accommodation: $287B',
            color: 'hsl(92, 70%, 50%)',
        },
        {
            id: 'Franchise and other',
            color: 'hsl(180, 70%, 50%)',
        },
        {
            id: 'Full-service restaurants: $260B',
            color: 'hsl(180, 70%, 50%)',
        },
        {
            id: 'Top 100 Independent restaurants: $2B',
            color: 'hsl(199, 70%, 50%)',
        },
    ],
    links: [
        {
            source: experiential,
            target: 'Food services and drinking places: $860B',
            value: 860,
        },
        {
            source: experiential,
            target: 'Arts, entertainment, recreation: $363B',
            value: 363,
        },
        {
            source: experiential,
            target: 'Accommodation: $287B',
            value: 287,
        },
        {
            source: 'Food services and drinking places: $860B',
            target: 'Franchise and other',
            value: 597,
        },
        {
            source: 'Food services and drinking places: $860B',
            target: 'Full-service restaurants: $260B',
            value: 263,
        },

        {
            source: 'Full-service restaurants: $260B',
            target: 'Top 100 Independent restaurants: $2B',
            value: 20,
        },
    ],
}

const marketGrowth = [
    {
        id: 'Amusements, gambling, and recreation industries',
        color: 'hsl(102, 70%, 50%)',

        data: [
            {
                x: 2010,
                y: 111.9,
            },
            {
                x: 2011,
                y: 115.5,
            },
            {
                x: 2012,
                y: 119.8,
            },
            {
                x: 2013,
                y: 124.6,
            },
            {
                x: 2014,
                y: 129.4,
            },
            {
                x: 2015,
                y: 136.1,
            },
            {
                x: 2016,
                y: 142.5,
            },
            {
                x: 2017,
                y: 152.5,
            },
            {
                x: 2018,
                y: 160.2,
            },
        ],
    },
    {
        id:
            'Performing arts, spectator sports, museums, and related activities',
        color: 'hsl(23, 70%, 50%)',

        data: [
            {
                x: 2010,
                y: 134.0,
            },
            {
                x: 2011,
                y: 136.6,
            },
            {
                x: 2012,
                y: 144.7,
            },
            {
                x: 2013,
                y: 149.5,
            },
            {
                x: 2014,
                y: 159.0,
            },
            {
                x: 2015,
                y: 169.1,
            },
            {
                x: 2016,
                y: 176.8,
            },
            {
                x: 2017,
                y: 189.0,
            },
            {
                x: 2018,
                y: 202.3,
            },
        ],
    },
    {
        id: 'Accommodation',
        color: 'hsl(275, 70%, 50%)',

        data: [
            {
                x: 2010,
                y: 185.7,
            },
            {
                x: 2011,
                y: 198.7,
            },
            {
                x: 2012,
                y: 214.9,
            },
            {
                x: 2013,
                y: 226.0,
            },
            {
                x: 2014,
                y: 244.2,
            },
            {
                x: 2015,
                y: 258.5,
            },
            {
                x: 2016,
                y: 267.1,
            },
            {
                x: 2017,
                y: 273.6,
            },
            {
                x: 2018,
                y: 286.7,
            },
        ],
    },

    {
        id: 'Food services and drinking places',
        color: 'hsl(348, 70%, 50%)',
        data: [
            {
                x: 2010,
                y: 547.7,
            },
            {
                x: 2011,
                y: 579.2,
            },
            {
                x: 2012,
                y: 614.0,
            },
            {
                x: 2013,
                y: 639.4,
            },
            {
                x: 2014,
                y: 680.0,
            },
            {
                x: 2015,
                y: 734.3,
            },
            {
                x: 2016,
                y: 769.6,
            },
            {
                x: 2017,
                y: 808.3,
            },
            {
                x: 2018,
                y: 858.9,
            },
        ],
    },
]

const restaurantProfit = [
    {
        id: 'Increase Significantly',
        label: 'Increase Significantly',
        value: 34.3,
        color: 'hsl(11, 70%, 50%)',
    },
    {
        id: 'Increase Somewhat',
        label: 'Increase Somewhat',
        value: 39.4,
        color: 'hsl(341, 70%, 50%)',
    },

    {
        id: 'Increase by a Little',
        label: 'Increase by a Little',
        value: 18.2,
        color: 'hsl(32, 70%, 50%)',
    },
    {
        id: 'Roughly Stay the Same',
        label: 'Roughly Stay the Same',
        value: 7.1,
        color: 'hsl(301, 70%, 50%)',
    },
    {
        id: 'Decrease by a Little',
        label: 'Decrease by a Little',
        value: 1,
        color: 'hsl(209, 70%, 50%)',
    },
]

const restaurantTreemap = {
    name: 'set1',

    color: [
        Colors.accentTint1,
        Colors.accentTint2,
        Colors.accentTint3,
        Colors.accentTint4,
        Colors.accentTint5,
        Colors.accentTint6,
        Colors.accentTint7,
        Colors.accentTint8,
    ],
    children: [
        {
            name: 'Social Media Ads',
            color: 'hsl(54, 70%, 50%)',
            loc: 67,
        },
        {
            name: 'Community/Event/Charity Sponsor',
            color: 'hsl(131, 70%, 50%)',
            loc: 53,
        },
        {
            name: 'Google/Search Engine Ads',
            color: 'hsl(97, 70%, 50%)',
            loc: 42,
        },
        {
            name: 'Newspaper/Magazine Ads',
            color: 'hsl(69, 70%, 50%)',
            loc: 32,
        },
        {
            name: 'Public Relations/Agency Partnerships',
            color: 'hsl(106, 70%, 50%)',
            loc: 24,
        },
        {
            name: 'Direct Mail Ads',
            color: 'hsl(244, 70%, 50%)',
            loc: 22,
        },
        {
            name: 'Television Ads',
            color: 'hsl(310, 70%, 50%)',
            loc: 11,
        },
        {
            name: 'Other',
            color: 'hsl(185, 70%, 50%)',
            loc: 5,
        },
        {
            name: 'None of These',
            color: 'hsl(352, 70%, 50%)',
            loc: 4,
        },
        {
            name: 'Radio Ads',
            color: 'hsl(97, 70%, 50%)',
            loc: 2,
        },
        {
            name: 'Billboards',
            color: 'hsl(155, 70%, 50%)',
            loc: 1,
        },
    ],
}

const customerBubble = {
    name: 'set',
    color: 'hsl(131, 70%, 50%)',
    children: [
        {
            name: 'set2',
            color: 'hsl(131, 70%, 50%)',
            children: [
                {
                    name: 'Multiple Times a Week',
                    color: 'hsl(54, 70%, 50%)',
                    loc: 45,
                },
                {
                    name: 'A Couple Times a Month',
                    color: 'hsl(131, 70%, 50%)',
                    loc: 24,
                },
                {
                    name: 'Once a Week',
                    color: 'hsl(97, 70%, 50%)',
                    loc: 20,
                },
                {
                    name: 'Every Day',
                    color: 'hsl(69, 70%, 50%)',
                    loc: 6,
                },
                {
                    name: 'A Couple of Times a Year',
                    color: 'hsl(106, 70%, 50%)',
                    loc: 3,
                },
                {
                    name: 'Multiple Times a Day',
                    color: 'hsl(244, 70%, 50%)',
                    loc: 2,
                },
            ],
        },
    ],
}

const metaBubble = {
    name: 'set',
    children: [
        {
            name: 'Re-purpose Leftover Kitchen Trim',
            loc: 38,
        },
        {
            name: 'Offer Guests Multiple Portion Choices',
            loc: 26,
        },
        {
            name: 'None of the Above',
            loc: 26,
        },
        {
            name: 'Composting',
            loc: 25,
        },
        {
            name: 'Use Smaller Plates',
            loc: 20,
        },
        {
            name: 'Other',
            loc: 6,
        },
    ],
}

const cosmopolitesTreemap = {
    name: 'set1',
    color: [
        Colors.coral,
        Colors.deepcoral,
        Colors.deepcoral,
        Colors.accentRed,
        Colors.accentRed,
    ],
    children: [
        {
            name: 'White',
            loc: 77.1,
        },
        {
            name: 'Asian',
            loc: 17.7,
        },
        {
            name: 'Middle Eastern',
            loc: 2.4,
        },
        {
            name: 'Latinx',
            loc: 1.8,
        },
        {
            name: 'Black',
            loc: 1,
        },
    ],
}

export default {
    marketSize,
    marketGrowth,
    restaurantProfit,
    restaurantTreemap,
    customerBubble,
    metaBubble,
    cosmopolitesTreemap,
}
