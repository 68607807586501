import React from 'react'
import { Image, View, Text } from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Colors, TextStyles, Layout, Icons } from '../constants'

export default class MetaWakeUpHMW extends React.Component {
    renderSeparator = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.lightBackground,
                    paddingTop: Layout.paddingMedium,
                    marginBottom: Layout.paddingMedium,
                }}
            />
        )
    }

    render() {
        return (
            <View style={Layout.projectContainer}>
                <View
                    style={[
                        Layout.columnOne,
                        {
                            paddingHorizontal: Layout.isMediumDevice
                                ? 0
                                : Layout.paddingLarge,
                        },
                    ]}
                >
                    <View
                        style={[
                            Layout.cardContent,
                            {
                                // borderColor: Colors.gray0,
                                // borderWidth: 1,
                                backgroundColor: Colors.accentYellow,
                                maxWidth: 720,
                            },
                        ]}
                    >
                        <View
                            style={[
                                Layout.columnContainer,
                                { justifyContent: 'flex-start' },
                            ]}
                        >
                            <Image
                                source={require('../assets/images/dominique-crenn-AD-topbanner.jpg')}
                                style={[
                                    Layout.avatarImageCircle,
                                    { marginRight: Layout.paddingMedium },
                                ]}
                                resizeMode={'cover'}
                            />

                            <View
                                style={{
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                    //padding: Layout.paddingMedium,
                                }}
                            >
                                <Text style={TextStyles.ParagraphBold}>
                                    Dominique Crenn
                                </Text>
                                <Text style={TextStyles.Overline}>
                                    Human. Woman. Activist
                                </Text>
                            </View>
                        </View>
                        <Text
                            style={[
                                TextStyles.Heading3,
                                {
                                    paddingVertical: Layout.paddingLarge,
                                },
                            ]}
                        >
                            How might we inspire people in our industry and
                            beyond to promote sustainable lifestyles, in
                            addition to reducing food waste in restaurants?
                        </Text>

                        {this.renderSeparator()}
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Feather
                                style={{
                                    alignSelf: 'center',
                                    paddingRight: Layout.paddingMedium,
                                }}
                                color={Colors.Icon}
                                size={Icons.small}
                                name={'instagram'}
                            />
                            <Feather
                                style={{
                                    alignSelf: 'center',
                                    paddingRight: Layout.paddingMedium,
                                }}
                                color={Colors.Icon}
                                size={Icons.small}
                                name={'twitter'}
                            />
                            <Text style={TextStyles.Paragraph}>
                                #WakeUp{'  '}#WhatNext
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
}
