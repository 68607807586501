import 'react-native-gesture-handler'
import { StatusBar } from 'expo-status-bar'
import React from 'react'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import useCachedResources from './hooks/useCachedResources'
import useColorScheme from './hooks/useColorScheme'
import Navigation from './navigation'
//import AppNavigator from './navigation/AppNavigator' // Older nav model
import { WindowResizeProvider } from './context/WindowResizeContext'

import { ContextProvider } from './context/Context'
import { AuthProvider } from './context/AuthContext'
import { LogBox } from 'react-native'

export default function App() {
    // @todo remove when RN upstream is fixed
    console.ignoredYellowBox = ['Warning: Failed propType: SceneView']
    LogBox.ignoreLogs(['Warning: ...']) // Ignore log notification by message
    LogBox.ignoreAllLogs() //Ignore all log notifications

    const isLoadingComplete = useCachedResources()
    const colorScheme = useColorScheme()

    if (!isLoadingComplete) {
        return null
    } else {
        return (
            <AuthProvider>
                <ContextProvider>
                    <WindowResizeProvider>
                        <SafeAreaProvider>
                            {/* <AppNavigator /> */}
                            <Navigation colorScheme={colorScheme} />
                            <StatusBar />
                        </SafeAreaProvider>
                    </WindowResizeProvider>
                </ContextProvider>
            </AuthProvider>
        )
    }
}
