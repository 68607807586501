import React from 'react'
import {
    Platform,
    Image,
    ImageBackground,
    ScrollView,
    TouchableOpacity,
    Text,
    View,
} from 'react-native'
import * as Linking from 'expo-linking'
import { Layout, Buttons, Colors, TextStyles, Icons } from '../../constants'
import { Consumer } from '../../context/Context'
import { WindowResizeConsumer } from '../../context/WindowResizeContext'
import Button from '../../components/Button'
import Footer from '../../components/Footer'

export default class AuthHomeScreen extends React.Component {
    state = {
        active: false,
        pageYOffset: 0,
        blurAmount: 0,
    }

    componentDidMount = () => {
        this.props.navigation.setParams({ active: true })

        this.context.initializeUserAccount()

        /* For special functions only */
        //this.context.uploadVideoToFirestore()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isFocused !== this.props.isFocused) {
            this.context.initializeUserAccount()
        }
    }

    onScroll = async (event) => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        const blurAmount = (pageYOffset + 1 / Layout.SCREEN_HEIGHT) * 0.01
        this.setState({ pageYOffset: pageYOffset, blurAmount: blurAmount })

        if (pageYOffset >= Layout.SCREEN_HEIGHT) {
            this.props.navigation.setParams({ active: false })
        } else {
            this.props.navigation.setParams({ active: true })
        }
    }

    renderSeparator() {
        return (
            <View
                style={{
                    //flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                }}
            />
        )
    }

    renderColumnSeparator = () => {
        return (
            <View
                style={{
                    backgroundColor: Colors.white,
                    width: Layout.isMediumDevice ? '100%' : 1,
                    height: Layout.isMediumDevice ? 1 : null,
                }}
            />
        )
    }

    renderTimelineSeparator = (color) => {
        return (
            <View
                style={{
                    backgroundColor: color ? color : Colors.tintColor,
                    width: 2,
                    height: Layout.paddingLarge,
                    marginLeft: 18,
                    borderRadius: 8,
                    marginVertical: 4,
                }}
            />
        )
    }

    render() {
        return (
            <WindowResizeConsumer>
                {(windowContext) => (
                    <View
                        style={{ flex: 1 }}
                        ref={(ref) => {
                            this.windowContext = windowContext
                        }}
                    >
                        <Consumer>
                            {(context) => {
                                return (
                                    <View
                                        style={{
                                            flex: 1,
                                        }}
                                        ref={(ref) => {
                                            this.context = context
                                        }}
                                    >
                                        <ImageBackground
                                            source={require('../../assets/images/tareq-ajalyakin-hgs-WgmT8GA-unsplash.jpg')}
                                            resizeMode={'cover'}
                                            blurRadius={this.state.blurAmount}
                                            style={[
                                                Layout.sectionContainer,
                                                {
                                                    height: Layout.SCREEN_HEIGHT,
                                                    backgroundColor:
                                                        Colors.gray0,
                                                    zIndex: -100,
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                },
                                            ]}
                                        >
                                            {/* <Image
                                                source={require('../../assets/images/cedric-tyaglovsky-U2Ug1A6tQew-unsplash.jpg')}
                                                resizeMode={'cover'}
                                                style={Layout.homeHeroImage}
                                            /> */}

                                            <View
                                                style={
                                                    Layout.contentOverlayHero
                                                }
                                            />
                                        </ImageBackground>

                                        {/* <View
                                            style={[
                                                Layout.sectionContainer,
                                                {
                                                    backgroundColor:
                                                        Colors.gray0,
                                                    zIndex: -100,
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                },
                                            ]}
                                        >
                                            <Image
                                                source={require('../../assets/images/cedric-tyaglovsky-U2Ug1A6tQew-unsplash.jpg')}
                                                resizeMode={'cover'}
                                                style={Layout.homeHeroImage}
                                            />
                                        </View> */}

                                        <ScrollView
                                            style={{
                                                flex: 1,
                                            }}
                                            onScroll={(event) =>
                                                this.onScroll(event)
                                            }
                                            // onScroll={event =>
                                            //     Layout.isTabletDevice
                                            //         ? this.onScroll(event)
                                            //         : null
                                            // }
                                            scrollEventThrottle={16}
                                        >
                                            <View
                                                style={[
                                                    Layout.sectionContainer,
                                                    {
                                                        backgroundColor:
                                                            'transparent',
                                                    },
                                                ]}
                                            >
                                                <View style={Layout.columnOne}>
                                                    <View
                                                        style={{
                                                            alignSelf: 'center',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            paddingVertical:
                                                                Layout.paddingXL,
                                                        }}
                                                    >
                                                        <Text
                                                            style={[
                                                                TextStyles.HeadingHero,
                                                                {
                                                                    textAlign:
                                                                        'center',
                                                                    color: Colors.white,
                                                                    paddingBottom:
                                                                        Layout.paddingLarge,
                                                                },
                                                            ]}
                                                        >
                                                            Morphosis
                                                            {Layout.isSmallDevice
                                                                ? '\n'
                                                                : ' '}
                                                            Wear
                                                        </Text>
                                                        <Text
                                                            style={[
                                                                Layout.isMediumDevice
                                                                    ? TextStyles.ParagraphInverse
                                                                    : TextStyles.Heading3Inverse,
                                                                {
                                                                    textAlign:
                                                                        'center',
                                                                    // paddingBottom:
                                                                    //     Layout.paddingXL,
                                                                },
                                                            ]}
                                                        >
                                                            We’re a
                                                            digital-native
                                                            maison crafting
                                                            products for web3
                                                            and the real world.
                                                        </Text>
                                                    </View>

                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                Layout.isTabletDevice
                                                                    ? 'column'
                                                                    : 'row',
                                                        }}
                                                    >
                                                        {/* <Button
                                                            iconRight={true}
                                                            iconName={
                                                                'external-link'
                                                            }
                                                            onPress={() => {
                                                                this.props.navigation.navigate(
                                                                    'AuthAgreement'
                                                                )
                                                            }}
                                                            buttonStyle={[
                                                                Buttons.button,
                                                                {
                                                                    alignSelf:
                                                                        'center',
                                                                    minWidth: 160,
                                                                },
                                                            ]}
                                                            title={
                                                                'View collection on Opensea'
                                                            }
                                                        /> */}
                                                        {/* <View
                                                            style={{
                                                                height: 20,
                                                                width: 20,
                                                            }}
                                                        /> */}
                                                        {context.userExists ? (
                                                            <Button
                                                                onPress={() => {
                                                                    this.props.navigation.navigate(
                                                                        'MorphosisWear'
                                                                    )
                                                                }}
                                                                buttonStyle={[
                                                                    {
                                                                        alignSelf:
                                                                            'center',
                                                                        backgroundColor:
                                                                            Colors.accentYellow,
                                                                        borderColor:
                                                                            'transparent',
                                                                        //borderRadius: 3,
                                                                    },
                                                                ]}
                                                                textColor={
                                                                    Colors.tintColor
                                                                }
                                                                title={
                                                                    'See our vision'
                                                                }
                                                            />
                                                        ) : (
                                                            <Button
                                                                // iconRight={true}
                                                                // iconName={'log-in'}
                                                                onPress={() => {
                                                                    this.props.navigation.navigate(
                                                                        'AuthAgreement'
                                                                    )
                                                                }}
                                                                buttonStyle={[
                                                                    Buttons.button,
                                                                    {
                                                                        alignSelf:
                                                                            'center',
                                                                        backgroundColor:
                                                                            Colors.accentYellow,
                                                                        borderColor:
                                                                            'transparent',
                                                                        minWidth: 160,
                                                                    },
                                                                ]}
                                                                textColor={
                                                                    Colors.tintColor
                                                                }
                                                                title={'Log in'}
                                                            />
                                                        )}
                                                        <View
                                                            style={{
                                                                height: 20,
                                                                width: 20,
                                                            }}
                                                        />
                                                        <Button
                                                            iconRight={true}
                                                            iconName={
                                                                'external-link'
                                                            }
                                                            onPress={() => {
                                                                if (
                                                                    Platform.OS ==
                                                                    'web'
                                                                ) {
                                                                    window.open(
                                                                        'https://opensea.io/collection/morphosis-wear',
                                                                        '_blank'
                                                                    )
                                                                } else {
                                                                    Linking.openURL(
                                                                        'https://opensea.io/collection/morphosis-wear'
                                                                    ) // normal Linking react-native
                                                                }
                                                            }}
                                                            buttonStyle={[
                                                                Buttons.button,
                                                                {
                                                                    alignSelf:
                                                                        'center',
                                                                    minWidth: 160,
                                                                },
                                                            ]}
                                                            title={
                                                                'View collection on Opensea'
                                                            }
                                                        />
                                                        {/* <Button
                                                            iconRight={true}
                                                            iconName={
                                                                'external-link'
                                                            }
                                                            // iconColor={
                                                            //     Colors.tintColor
                                                            // }
                                                            onPress={() => {
                                                                this.props.navigation.navigate(
                                                                    'AuthAgreement'
                                                                )
                                                            }}
                                                            // textColor={
                                                            //     Colors.tintColor
                                                            // }
                                                            buttonStyle={[
                                                                Buttons.buttonOutlineInverse,
                                                                {
                                                                    alignSelf:
                                                                        'center',
                                                                },
                                                            ]}
                                                            title={
                                                                'View collection on Opensea'
                                                            }
                                                        /> */}
                                                    </View>
                                                </View>
                                            </View>

                                            <View
                                                style={[
                                                    Layout.projectContainer,
                                                    {
                                                        minHeight: 0,
                                                        paddingVertical: 0,
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={
                                                        Layout.columnContainer
                                                    }
                                                >
                                                    <View
                                                        style={
                                                            Layout.columnThreeType
                                                        }
                                                    >
                                                        <View
                                                            style={[
                                                                Layout.contentOverlayType,
                                                                {
                                                                    alignItems:
                                                                        'flex-end',
                                                                },
                                                            ]}
                                                        >
                                                            <Text
                                                                style={[
                                                                    TextStyles.HeadingPoem,
                                                                    {
                                                                        textAlign:
                                                                            'center',
                                                                    },
                                                                ]}
                                                            >
                                                                Made
                                                            </Text>
                                                        </View>
                                                    </View>

                                                    <View
                                                        style={[
                                                            Layout.columnThreeType,
                                                            { zIndex: 10 },
                                                        ]}
                                                    >
                                                        <View
                                                            style={[
                                                                Layout.contentOverlayType,
                                                                {
                                                                    justifyContent:
                                                                        Layout.isMediumDevice
                                                                            ? 'center'
                                                                            : 'flex-start',
                                                                },
                                                            ]}
                                                        >
                                                            <Text
                                                                style={[
                                                                    TextStyles.HeadingPoem,
                                                                    {
                                                                        textAlign:
                                                                            'center',
                                                                    },
                                                                ]}
                                                            >
                                                                for
                                                            </Text>
                                                        </View>
                                                    </View>

                                                    <View
                                                        style={
                                                            Layout.columnThreeType
                                                        }
                                                    >
                                                        <View
                                                            style={[
                                                                Layout.contentOverlayType,
                                                                {
                                                                    justifyContent:
                                                                        Layout.isMediumDevice
                                                                            ? 'center'
                                                                            : 'flex-end',
                                                                    alignItems:
                                                                        'flex-start',
                                                                },
                                                            ]}
                                                        >
                                                            <Text
                                                                style={[
                                                                    TextStyles.HeadingPoem,
                                                                    {
                                                                        textAlign:
                                                                            'center',
                                                                    },
                                                                ]}
                                                            >
                                                                all
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>

                                            <View
                                                style={[
                                                    Layout.projectContainer,
                                                    {
                                                        // backgroundColor:
                                                        //     'rgba(255,255,255, 0.9)',
                                                        // borderWidth: 40,
                                                        // borderColor: 'white',

                                                        backgroundColor:
                                                            Colors.accentYellow,
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={[
                                                        Layout.columnOne,
                                                        {
                                                            maxWidth:
                                                                Layout.isTabletDevice
                                                                    ? 480
                                                                    : 720,
                                                        },
                                                    ]}
                                                >
                                                    <Text
                                                        style={[
                                                            Layout.isMediumDevice
                                                                ? TextStyles.Paragraph
                                                                : TextStyles.Heading3,
                                                            {
                                                                textAlign:
                                                                    'center',
                                                                paddingBottom:
                                                                    Layout.paddingXL,
                                                            },
                                                        ]}
                                                    >
                                                        All people have a dark
                                                        night of the soul, a
                                                        test, their moment of
                                                        truth when who they
                                                        pretend to be falls
                                                        away. The false self is
                                                        challenged and the real
                                                        self emerges to make
                                                        way. {'\n\n'}Death of a
                                                        parent. {'\n'}
                                                        Battle with addiction.
                                                        {'\n'}
                                                        Depression.{'\n'} Loss
                                                        of a job.{'\n'}
                                                        Coming out as gay.{'\n'}
                                                        Winning a battle with
                                                        cancer.{'\n'} Risking
                                                        stepping out of old
                                                        habits that don’t serve
                                                        the self and growing
                                                        stronger to live truth.
                                                        {'\n\n'}
                                                        Authenticity.{'\n'}
                                                        Boldness.
                                                        {'\n'}
                                                        Rising from the ashes
                                                        where the old self dies
                                                        away.
                                                    </Text>
                                                    <Button
                                                        onPress={() =>
                                                            // this.props.navigation.navigate(
                                                            //     'RequestInfo'
                                                            // )
                                                            this.props.navigation.navigate(
                                                                'PublicStack',
                                                                {
                                                                    screen: 'RequestInfo',
                                                                }
                                                            )
                                                        }
                                                        textColor={
                                                            Colors.tintColor
                                                        }
                                                        buttonStyle={[
                                                            Buttons.buttonOutline,
                                                            {
                                                                alignSelf:
                                                                    'center',
                                                            },
                                                        ]}
                                                        title={
                                                            'Follow our journey'
                                                        }
                                                    />
                                                </View>
                                            </View>

                                            <View
                                                // source={require('../../assets/images/tareq-ajalyakin-QGlntT8_57o-unsplash.jpg')}
                                                // resizeMode={'cover'}
                                                // blurRadius={12}
                                                style={[
                                                    Layout.projectContainer,
                                                    {
                                                        backgroundColor:
                                                            'rgba(255,255,255, 0.8)',
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={[
                                                        Layout.columnContainer,
                                                        {
                                                            flexDirection:
                                                                'row-reverse',
                                                        },
                                                    ]}
                                                >
                                                    <View
                                                        style={[
                                                            Layout.columnTwo,
                                                            {
                                                                justifyContent:
                                                                    'center',
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={[
                                                                TextStyles.Heading1,
                                                                {
                                                                    textAlign:
                                                                        'center',
                                                                    maxWidth: 720,
                                                                    paddingBottom:
                                                                        Layout.paddingLarge,
                                                                },
                                                            ]}
                                                        >
                                                            Morphosis reimagines
                                                            the possibilities of
                                                            performance wear.
                                                        </Text>
                                                    </View>

                                                    <View
                                                        style={Layout.columnTwo}
                                                    >
                                                        <View
                                                            style={{
                                                                flexDirection:
                                                                    'row',
                                                                alignItems:
                                                                    'center',
                                                                paddingBottom:
                                                                    Layout.paddingSmall,
                                                            }}
                                                        >
                                                            <Image
                                                                source={require('../../assets/svgs/shop-like.svg')}
                                                                style={{
                                                                    width: Icons.XL,
                                                                    height: Icons.XL,
                                                                    marginRight:
                                                                        Layout.paddingMedium,
                                                                }}
                                                                resizeMode={
                                                                    'cover'
                                                                }
                                                            />
                                                            <Text
                                                                style={[
                                                                    TextStyles.Paragraph,
                                                                    {
                                                                        lineHeight:
                                                                            TextStyles.ListParagraphLineHeight,
                                                                    },
                                                                ]}
                                                            >
                                                                Maison Morphosis
                                                                — creators of
                                                                artifacts that
                                                                inhabit both the
                                                                digital and real
                                                                world
                                                            </Text>
                                                        </View>
                                                        {this.renderTimelineSeparator()}

                                                        <View
                                                            style={{
                                                                flexDirection:
                                                                    'row',
                                                                alignItems:
                                                                    'center',
                                                                paddingBottom:
                                                                    Layout.paddingSmall,
                                                            }}
                                                        >
                                                            <Image
                                                                source={require('../../assets/svgs/charging-light-idea.svg')}
                                                                style={{
                                                                    width: Icons.XL,
                                                                    height: Icons.XL,
                                                                    marginRight:
                                                                        Layout.paddingMedium,
                                                                }}
                                                                resizeMode={
                                                                    'cover'
                                                                }
                                                            />
                                                            <Text
                                                                style={[
                                                                    TextStyles.Paragraph,
                                                                    {
                                                                        lineHeight:
                                                                            TextStyles.ListParagraphLineHeight,
                                                                    },
                                                                ]}
                                                            >
                                                                Morphosis Wear —
                                                                a vision for a
                                                                head-to-toe
                                                                fashion lineup
                                                                that crosses
                                                                digital
                                                                boundaries
                                                            </Text>
                                                        </View>
                                                        {this.renderTimelineSeparator()}

                                                        <View
                                                            style={{
                                                                flexDirection:
                                                                    'row',
                                                                alignItems:
                                                                    'center',
                                                                paddingBottom:
                                                                    Layout.paddingSmall,
                                                            }}
                                                        >
                                                            <Image
                                                                source={require('../../assets/svgs/award-star-head.svg')}
                                                                style={{
                                                                    width: Icons.XL,
                                                                    height: Icons.XL,
                                                                    marginRight:
                                                                        Layout.paddingMedium,
                                                                }}
                                                                resizeMode={
                                                                    'cover'
                                                                }
                                                            />
                                                            <Text
                                                                style={[
                                                                    TextStyles.Paragraph,
                                                                    {
                                                                        lineHeight:
                                                                            TextStyles.ListParagraphLineHeight,
                                                                    },
                                                                ]}
                                                            >
                                                                Morphosis Wear —
                                                                design sketches
                                                                for shoes,
                                                                apparel, and
                                                                accessories
                                                            </Text>
                                                        </View>
                                                        {this.renderTimelineSeparator(
                                                            Colors.disabled
                                                        )}

                                                        <View
                                                            style={{
                                                                flexDirection:
                                                                    'row',
                                                                alignItems:
                                                                    'center',
                                                                paddingBottom:
                                                                    Layout.paddingSmall,
                                                            }}
                                                        >
                                                            <Image
                                                                source={require('../../assets/svgs/smartphone-payment-touch.svg')}
                                                                style={{
                                                                    width: Icons.XL,
                                                                    height: Icons.XL,
                                                                    marginRight:
                                                                        Layout.paddingMedium,
                                                                }}
                                                                resizeMode={
                                                                    'cover'
                                                                }
                                                            />
                                                            <Text
                                                                style={[
                                                                    TextStyles.Paragraph,
                                                                    {
                                                                        lineHeight:
                                                                            TextStyles.ListParagraphLineHeight,
                                                                    },
                                                                ]}
                                                            >
                                                                Morphosis Wear —
                                                                digital drops
                                                                for web3
                                                                aficionados
                                                            </Text>
                                                        </View>

                                                        {this.renderTimelineSeparator(
                                                            Colors.disabled
                                                        )}

                                                        <View
                                                            style={{
                                                                flexDirection:
                                                                    'row',
                                                                alignItems:
                                                                    'center',
                                                                paddingBottom:
                                                                    Layout.paddingSmall,
                                                            }}
                                                        >
                                                            <Image
                                                                source={require('../../assets/svgs/button-loop-arrow.svg')}
                                                                style={{
                                                                    width: Icons.XL,
                                                                    height: Icons.XL,
                                                                    marginRight:
                                                                        Layout.paddingMedium,
                                                                    //opacity: 0.4,
                                                                }}
                                                                resizeMode={
                                                                    'cover'
                                                                }
                                                            />
                                                            <Text
                                                                style={[
                                                                    TextStyles.Paragraph,
                                                                    {
                                                                        lineHeight:
                                                                            TextStyles.ListParagraphLineHeight,
                                                                    },
                                                                ]}
                                                            >
                                                                Made by
                                                                Morphosis —
                                                                products for the
                                                                digital and real
                                                                worlds
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>

                                            <View
                                                style={[
                                                    Layout.sectionFooterContainer,
                                                    // {
                                                    //     backgroundColor:
                                                    //         'rgba(255,255,255, 0.9)',
                                                    // },
                                                ]}
                                            >
                                                <View style={Layout.columnOne}>
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading1,
                                                            {
                                                                textAlign:
                                                                    'center',
                                                                paddingBottom:
                                                                    Layout.isMediumDevice
                                                                        ? Layout.paddingLarge
                                                                        : Layout.paddingXL,
                                                            },
                                                        ]}
                                                    >
                                                        Morphosis Wear is made
                                                        for you.
                                                    </Text>

                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                Layout.isTabletDevice
                                                                    ? 'column'
                                                                    : 'row',
                                                        }}
                                                    >
                                                        {/* <Button
                                                            onPress={() => {
                                                                this.props.navigation.navigate(
                                                                    'MorphosisWear'
                                                                )
                                                            }}
                                                            buttonStyle={[
                                                                {
                                                                    alignSelf:
                                                                        'center',
                                                                    backgroundColor:
                                                                        Colors.accentYellow,
                                                                    borderColor:
                                                                        'transparent',
                                                                    //borderRadius: 3,
                                                                },
                                                            ]}
                                                            textColor={
                                                                Colors.tintColor
                                                            }
                                                            title={
                                                                'View collection on Opensea'
                                                            }
                                                            // details={
                                                            //     'On Opensea'
                                                            // }
                                                        /> */}
                                                        <Button
                                                            iconRight={true}
                                                            iconName={
                                                                'external-link'
                                                            }
                                                            iconColor={
                                                                Colors.tintColor
                                                            }
                                                            onPress={() => {
                                                                if (
                                                                    Platform.OS ==
                                                                    'web'
                                                                ) {
                                                                    window.open(
                                                                        'https://opensea.io/collection/morphosis-wear',
                                                                        '_blank'
                                                                    )
                                                                } else {
                                                                    Linking.openURL(
                                                                        'https://opensea.io/collection/morphosis-wear'
                                                                    ) // normal Linking react-native
                                                                }
                                                            }}
                                                            textColor={
                                                                Colors.tintColor
                                                            }
                                                            buttonStyle={[
                                                                Buttons.buttonOutline,
                                                                {
                                                                    alignSelf:
                                                                        'center',
                                                                },
                                                            ]}
                                                            title={
                                                                'View collection on Opensea'
                                                            }
                                                        />
                                                        {/* <View
                                                            style={{
                                                                height: 20,
                                                                width: 20,
                                                            }}
                                                        /> */}
                                                        {/* <Button
                                                            onPress={() =>
                                                                this.props.navigation.navigate(
                                                                    'RequestInfo'
                                                                )
                                                            }
                                                            textColor={
                                                                Colors.tintColor
                                                            }
                                                            buttonStyle={[
                                                                Buttons.buttonOutline,
                                                                {
                                                                    alignSelf:
                                                                        'center',
                                                                },
                                                            ]}
                                                            title={
                                                                'Follow our journey'
                                                            }
                                                        /> */}
                                                    </View>
                                                </View>
                                                <Footer
                                                    navigation={
                                                        this.props.navigation
                                                    }
                                                />
                                            </View>
                                        </ScrollView>
                                    </View>
                                )
                            }}
                        </Consumer>
                    </View>
                )}
            </WindowResizeConsumer>
        )
    }
}
