const metaValues = [
    {
        uri: require('../assets/svgs/renewable-energy-paper-turbine.svg'),
        uriInverse: require('../assets/svgs/renewable-energy-paper-turbine-inverse.svg'),
        label: 'Sustainability',
        description:
            'We’re an environmentally sustainable brand, aiming to reduce waste. We believe that is what our future needs.',
    },
    {
        uri: require('../assets/svgs/touch-id.svg'),
        uriInverse: require('../assets/svgs/touch-id-inverse.svg'),
        label: 'Integrity',
        description:
            'Founded on an athletic tradition and taking cues from the Food space, Morphosis honors makers as well as fashion enthusiasts.',
    },
    {
        uri: require('../assets/svgs/wifi-heart.svg'),
        uriInverse: require('../assets/svgs/wifi-heart-inverse.svg'),
        label: 'Accessibility',
        description:
            'With inclusion and self-expression in mind, Morphosis is designed for all genders, all forms of creativity, and people of all ages.',
    },
]

const metaDesignApproach = [
    {
        uri: require('../assets/images/MatrixBody.png'),
        description:
            'The Body Matrix let us consider how our designs fit on different body types.',
    },
    {
        uri: require('../assets/images/MatrixEnvironment.png'),
        description:
            'The Environment Matrix helped us think about the transition between spaces.',
    },
    {
        uri: require('../assets/images/MatrixConcept.png'),
        description:
            'The Concept Matrix allowed us to explore a range of looks we wanted to achieve.',
    },
    {
        uri: require('../assets/images/MatrixExpression.png'),
        description:
            'The Expression Matrix pushed us to discover unorthodox use of materials.',
    },
]

export default {
    metaValues,
    metaDesignApproach,
}
