import React from 'react'
import {
    Platform,
    FlatList,
    Image,
    Text,
    View,
    StyleSheet,
    ImageBackground,
} from 'react-native'
import * as Linking from 'expo-linking'
import { Feather } from '@expo/vector-icons'
import { Colors, Icons, TextStyles, Layout, Buttons } from '../constants'
import ButtonIcon from './ButtonIcon'
import Button from './Button'

function Item({ image, blur }) {
    return (
        <ImageBackground
            source={image}
            style={{
                backgroundColor: Colors.disabled,
                width: Layout.isTabletDevice
                    ? Layout.SCREEN_WIDTH
                    : Layout.SCREEN_WIDTH / 3,
                height: Layout.isTabletDevice
                    ? Layout.SCREEN_WIDTH
                    : Layout.SCREEN_WIDTH / 3,
            }}
            resizeMode={'cover'}
            blurRadius={blur ? 10 : 0}
        >
            <View
                style={[
                    styles.overlay,
                    { opacity: blur ? 1 : 0, justifyContent: 'center' },
                ]}
            >
                <View
                    style={{
                        height: Icons.XL * 2,
                        width: Icons.XL * 2,
                        borderRadius: Icons.XL,
                        borderWidth: 2,
                        borderColor: Colors.inverseIcon,
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        marginBottom: Layout.paddingMedium,
                    }}
                >
                    <Feather
                        style={{
                            alignSelf: 'center',
                        }}
                        color={Colors.inverseIcon}
                        size={Icons.large}
                        name={'lock'}
                    />
                </View>
                {/* <Button
                    //navigation={navigation}
                    onPress={() =>
                        this.props.navigation.navigate('PublicStack', {
                            screen: 'RequestInfo',
                        })
                    }
                    buttonStyle={[
                        Buttons.buttonUnderline,
                        {
                            marginHorizontal: Layout.paddingMedium,
                        },
                    ]}
                    textColor={Colors.white}
                    textStyle={{
                        textDecorationLine: 'underline',
                    }}
                    title={'Get updates on next drop'}
                /> */}
                <Text
                    style={[
                        TextStyles.CaptionInverse,
                        {
                            textAlign: 'center',
                        },
                    ]}
                >
                    Next drop
                </Text>
            </View>
        </ImageBackground>
    )
}

export default class ImageCarousel extends React.Component {
    state = {
        currentIndex: 0,
    }

    onScroll = async (event) => {
        const pageXOffset = await event.nativeEvent.contentOffset.x

        const scrollIndex = pageXOffset / Layout.SCREEN_WIDTH

        this.setState({ currentIndex: Math.floor(scrollIndex) })
    }

    scrollToPrevious = async () => {
        const carouselLength = Layout.isMediumDevice
            ? this.props.imageData.length
            : Math.ceil(this.props.imageData.length / 3)

        await this.setState({
            currentIndex:
                (carouselLength + this.state.currentIndex - 1) % carouselLength,
        })

        // console.log('currentIndex', this.state.currentIndex)
        // console.log(
        //     'scrollPreviousIndex',
        //     (carouselLength + this.state.currentIndex - 1) % carouselLength
        // )

        this.flatListRef.scrollToOffset({
            animated: true,
            offset: Layout.SCREEN_WIDTH * this.state.currentIndex,
        })
    }

    scrollToNext = async () => {
        const carouselLength = Layout.isMediumDevice
            ? this.props.imageData.length
            : Math.ceil(this.props.imageData.length / 3)

        await this.setState({
            currentIndex:
                (carouselLength + this.state.currentIndex + 1) % carouselLength,
        })

        // console.log('currentIndex', this.state.currentIndex)

        // console.log(
        //     'scrollNextIndex',
        //     (carouselLength + this.state.currentIndex + 1) % carouselLength
        // )
        this.flatListRef.scrollToOffset({
            animated: true,
            offset: Layout.SCREEN_WIDTH * this.state.currentIndex,
        })
    }

    render() {
        return (
            <View style={Layout.projectContainer}>
                <View
                    style={{
                        padding: Layout.paddingLarge,
                        alignSelf: 'center',
                    }}
                >
                    <Text
                        style={[
                            TextStyles.Heading1,
                            {
                                textAlign: 'center',
                            },
                        ]}
                    >
                        {this.props.title}
                    </Text>
                </View>
                <View
                    style={{
                        alignSelf: 'center',
                    }}
                >
                    <View
                        style={{
                            alignSelf: 'center',
                        }}
                    >
                        <FlatList
                            ref={(ref) => {
                                this.flatListRef = ref
                            }}
                            data={this.props.imageData}
                            keyExtractor={(item) => item.image}
                            navigation={this.props.navigation}
                            renderItem={({ item }) => (
                                <Item
                                    image={item.image}
                                    blur={item.blur}
                                    //navigation={this.props.navigation}
                                />
                            )}
                            ItemSeparatorComponent={() => (
                                <View
                                    style={{
                                        width: Layout.isMediumDevice ? 0 : 1,
                                    }}
                                />
                            )}
                            onScroll={(event) => this.onScroll(event)}
                            decelerationRate="fast"
                            style={{ width: Layout.SCREEN_WIDTH }}
                            contentContainerStyle={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            horizontal
                            pagingEnabled
                            showsHorizontalScrollIndicator={false}
                        />
                    </View>
                    <View style={Buttons.carouselButtonContainer}>
                        <ButtonIcon
                            iconName={'chevron-left'}
                            buttonStyle={[
                                Buttons.carouselButton,
                                {
                                    borderRightWidth: Layout.isMediumDevice
                                        ? 1
                                        : 0,
                                    borderColor: Colors.gray0,
                                },
                            ]}
                            onPress={this.scrollToPrevious}
                        />
                        <ButtonIcon
                            iconName={'chevron-right'}
                            buttonStyle={Buttons.carouselButton}
                            onPress={this.scrollToNext}
                        />
                    </View>
                </View>
                <Button
                    iconRight={true}
                    iconName={'external-link'}
                    iconColor={Colors.tintColor}
                    onPress={() => {
                        if (Platform.OS == 'web') {
                            window.open(
                                'https://opensea.io/collection/morphosis-wear',
                                '_blank'
                            )
                        } else {
                            Linking.openURL(
                                'https://opensea.io/collection/morphosis-wear'
                            ) // normal Linking react-native
                        }
                    }}
                    textColor={Colors.tintColor}
                    buttonStyle={[
                        Buttons.buttonOutline,
                        {
                            alignSelf: 'center',
                            marginTop: Layout.paddingLarge,
                        },
                    ]}
                    title={'View on Opensea'}
                />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    overlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0,0,0,0.7)',
    },
})
