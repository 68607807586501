import React from 'react'
import {
    Platform,
    TouchableOpacity,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import Modal from 'react-native-modal'
import * as Linking from 'expo-linking'
import { Colors, Buttons, Layout, TextStyles } from '../constants'
import { Consumer } from '../context/Context'
import Button from './Button'
import ButtonIcon from './ButtonIcon'
import PrivateDrawerNavigation from './PrivateDrawerNavigation'

export default class PrivateHeader extends React.Component {
    state = {
        isModalVisible: false,
    }

    render() {
        return (
            <Consumer>
                {(context) => {
                    return (
                        <View
                            style={{
                                flex: 1,
                                width: Layout.SCREEN_WIDTH,
                                alignSelf: 'center',
                            }}
                        >
                            <View
                                style={[
                                    styles.header,
                                    this.props.style,
                                    {
                                        height: Layout.isTabletDevice
                                            ? Layout.topNavHeightMobile
                                            : Layout.topNavHeightDesktop,
                                        paddingVertical: Layout.paddingMedium,
                                        paddingHorizontal: Layout.isTabletDevice
                                            ? Layout.paddingSmall
                                            : Layout.paddingLarge,
                                    },
                                ]}
                            >
                                <Modal
                                    isVisible={this.state.isModalVisible}
                                    //swipeDirection={'up'}
                                    animationIn={'slideInRight'}
                                    animationOut={'slideOutRight'}
                                    coverScreen={false}
                                    style={{ margin: 0 }}
                                >
                                    <View style={{ flex: 1 }}>
                                        <PrivateDrawerNavigation
                                            navigation={this.props.navigation}
                                            handlePress={() =>
                                                this.setState({
                                                    isModalVisible:
                                                        !this.state
                                                            .isModalVisible,
                                                })
                                            }
                                        />
                                    </View>
                                </Modal>
                                <TouchableOpacity
                                    style={{ zIndex: 100 }}
                                    onPress={() =>
                                        this.props.navigation.navigate(
                                            'PrivateHome'
                                        )
                                    }
                                >
                                    <Text
                                        numberOfLines={1}
                                        style={[
                                            Layout.isMediumDevice
                                                ? TextStyles.Logotype
                                                : TextStyles.LogotypeLarge,
                                            {
                                                paddingLeft:
                                                    Layout.isTabletDevice
                                                        ? Layout.paddingMedium
                                                        : 0,
                                            },
                                        ]}
                                    >
                                        Morphosis
                                    </Text>
                                </TouchableOpacity>
                                <View style={{ flex: 1 }} />
                                {Layout.isMediumDevice ? (
                                    <ButtonIcon
                                        onPress={() =>
                                            this.setState({
                                                isModalVisible:
                                                    !this.state.isModalVisible,
                                            })
                                        }
                                        iconName={
                                            this.state.isModalVisible
                                                ? 'x'
                                                : 'menu'
                                        }
                                        iconColor={Colors.tintColor}
                                        buttonStyle={[
                                            Buttons.menuButtonTransparentBG,
                                            { zIndex: 100 },
                                        ]}
                                    />
                                ) : (
                                    <View
                                        style={{
                                            //flex: 1,
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            alignSelf: 'flex-end',
                                        }}
                                    >
                                        <Button
                                            navigation={this.props.navigation}
                                            onPress={() =>
                                                this.props.navigation.navigate(
                                                    'MorphosisWear'
                                                )
                                            }
                                            buttonStyle={[
                                                Buttons.buttonUnderline,
                                                {
                                                    paddingHorizontal: 0,
                                                    marginLeft:
                                                        Layout.paddingLarge,
                                                },
                                            ]}
                                            textColor={Colors.tintColor}
                                            textStyle={TextStyles.Overline}
                                            // textStyle={{
                                            //     textDecorationLine: 'underline',
                                            // }}
                                            title={'Vision'}
                                        />
                                        <Button
                                            onPress={() => {
                                                if (Platform.OS == 'web') {
                                                    window.open(
                                                        'https://opensea.io/collection/morphosis-wear',
                                                        '_blank'
                                                    )
                                                } else {
                                                    Linking.openURL(
                                                        'https://opensea.io/collection/morphosis-wear'
                                                    ) // normal Linking react-native
                                                }
                                            }}
                                            buttonStyle={[
                                                Buttons.buttonUnderline,
                                                {
                                                    paddingHorizontal: 0,
                                                    marginLeft:
                                                        Layout.paddingLarge,
                                                },
                                            ]}
                                            textColor={Colors.tintColor}
                                            textStyle={TextStyles.Overline}
                                            // textStyle={{
                                            //     textDecorationLine: 'underline',
                                            // }}
                                            title={'Opensea'}
                                        />
                                        <Button
                                            navigation={this.props.navigation}
                                            onPress={() =>
                                                // this.props.navigation.navigate(
                                                //     'RequestInfo'
                                                // )
                                                this.props.navigation.navigate(
                                                    'PublicStack',
                                                    { screen: 'RequestInfo' }
                                                )
                                            }
                                            buttonStyle={[
                                                Buttons.buttonUnderline,
                                                {
                                                    paddingHorizontal: 0,
                                                    marginLeft:
                                                        Layout.paddingLarge,
                                                },
                                            ]}
                                            textColor={Colors.tintColor}
                                            textStyle={TextStyles.Overline}
                                            // textStyle={{
                                            //     textDecorationLine: 'underline',
                                            // }}
                                            title={'Follow'}
                                        />
                                    </View>
                                )}
                            </View>
                        </View>
                    )
                }}
            </Consumer>
        )
    }
}

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
})
