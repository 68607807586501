import React from 'react'
import { View, ImageBackground, Text } from 'react-native'
import { Video } from 'expo-av'
import { Buttons, Icons, Colors, TextStyles, Layout } from '../constants'
import ButtonIcon from './ButtonIcon'

export default class ProductFeatures extends React.Component {
    state = {
        selectedDescription: '',
        selectedPoster: null,
        selectedUri: null,
    }

    componentDidMount = async () => {
        const contentData = await this.props.contentData

        if (contentData) {
            const selectedDescription = await contentData[0].description
            const selectedPoster = await contentData[0].poster
            const selectedUri = await contentData[0].uri

            this.setState({
                selectedDescription: selectedDescription,
                selectedPoster: selectedPoster,
                selectedUri: selectedUri,
            })
        } else {
            return
        }
    }

    // componentDidMount = () => {
    //     const contentData = this.props.contentData

    //     if (contentData) {
    //         const selectedDescription = contentData[0].description
    //         const selectedPoster = contentData[0].poster
    //         const selectedUri = contentData[0].uri

    //         this.setState({
    //             selectedDescription: selectedDescription,
    //             selectedPoster: selectedPoster,
    //             selectedUri: selectedUri,
    //         })
    //     }
    // }

    setSelectedUri = async (selectedVideoKey) => {
        const contentData = await this.props.contentData
        const selectedDescription = await contentData[selectedVideoKey]
            .description
        const selectedPoster = await contentData[selectedVideoKey].poster
        const selectedUri = await contentData[selectedVideoKey].uri

        this.setState({
            selectedDescription,
            selectedPoster,
            selectedUri,
        })
    }

    // setSelectedUri = selectedVideoKey => {
    //     const contentData = this.props.contentData
    //     const selectedDescription = contentData[selectedVideoKey].description
    //     const selectedPoster = contentData[selectedVideoKey].poster
    //     const selectedUri = contentData[selectedVideoKey].uri

    //     this.setState({
    //         selectedDescription,
    //         selectedPoster,
    //         selectedUri,
    //     })
    // }

    renderImage = ({ uri }) => {
        return (
            <ImageBackground
                source={uri}
                style={{
                    alignSelf: 'center',
                    width: '100%',
                    height:
                        Layout.SCREEN_HEIGHT < 1080
                            ? Layout.SCREEN_HEIGHT * 0.5
                            : Layout.SCREEN_HEIGHT * 0.382,
                }}
                resizeMode={'contain'}
            />
        )
    }

    renderAppVideo = ({ uri, poster }) => {
        return (
            <View style={Layout.mockAppContainer}>
                <Video
                    source={{ uri: uri }}
                    style={Layout.mockAppVideo}
                    resizeMode="contain"
                    isMuted
                    rate={1.0}
                    volume={0}
                    usePoster={true}
                    posterSource={{ uri: poster }}
                    posterStyle={Layout.mockAppPoster}
                    useNativeControls={Layout.isMediumDevice ? true : false}
                    shouldPlay={Layout.isMediumDevice ? false : true}
                    isLooping={Layout.isMediumDevice ? false : true}
                />
            </View>
        )
    }

    render = () => {
        return (
            <View style={Layout.projectContainer}>
                <View
                    style={[Layout.columnContainer, { alignItems: 'center' }]}
                >
                    <View style={Layout.columnTwo}>
                        <Text
                            style={[
                                TextStyles.Heading3,
                                {
                                    paddingBottom: Layout.isMediumDevice
                                        ? Layout.paddingSmall
                                        : Layout.paddingMedium,
                                    textAlign: Layout.isMediumDevice
                                        ? 'center'
                                        : 'left',
                                },
                            ]}
                        >
                            {this.props.title}
                        </Text>
                        <View
                            style={{
                                minHeight: Layout.isMediumDevice
                                    ? 0
                                    : Layout.paddingXXL,
                            }}
                        >
                            <Text
                                style={[
                                    TextStyles.Heading1,
                                    {
                                        paddingBottom: Layout.isMediumDevice
                                            ? 0
                                            : Layout.paddingXL,
                                        textAlign: Layout.isMediumDevice
                                            ? 'center'
                                            : 'left',
                                    },
                                ]}
                            >
                                {this.state.selectedDescription}
                            </Text>
                        </View>

                        {/* {Layout.isMediumDevice ? (
                            <View />
                        ) : (
                            <View style={{ minHeight: Layout.paddingXXL }}>
                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            paddingBottom: Layout.paddingLarge,
                                            textAlign: Layout.isMediumDevice
                                                ? 'center'
                                                : 'left',
                                        },
                                    ]}
                                >
                                    {this.state.selectedDescription}
                                </Text>
                            </View>
                        )} */}

                        {Layout.isMediumDevice ? (
                            <View />
                        ) : (
                            <View
                                style={[
                                    Layout.columnContainer,
                                    { justifyContent: 'flex-start' },
                                ]}
                            >
                                <ButtonIcon
                                    onPress={() => {
                                        this.setSelectedUri(0)
                                    }}
                                    materialIcon={true}
                                    iconName={'numeric-1'}
                                    iconSize={Icons.large}
                                    buttonStyle={
                                        this.props.contentData[0].uri ===
                                        this.state.selectedUri
                                            ? Buttons.buttonOptionSelected
                                            : Buttons.buttonOption
                                    }
                                    iconColor={
                                        this.props.contentData[0].uri ===
                                        this.state.selectedUri
                                            ? Colors.inverseText
                                            : Colors.primaryText
                                    }
                                />

                                <ButtonIcon
                                    onPress={() => {
                                        this.setSelectedUri(1)
                                    }}
                                    materialIcon={true}
                                    iconName={'numeric-2'}
                                    iconSize={Icons.large}
                                    buttonStyle={
                                        this.props.contentData[1].uri ===
                                        this.state.selectedUri
                                            ? Buttons.buttonOptionSelected
                                            : Buttons.buttonOption
                                    }
                                    iconColor={
                                        this.props.contentData[1].uri ===
                                        this.state.selectedUri
                                            ? Colors.inverseText
                                            : Colors.primaryText
                                    }
                                />

                                <ButtonIcon
                                    onPress={() => {
                                        this.setSelectedUri(2)
                                    }}
                                    materialIcon={true}
                                    iconName={'numeric-3'}
                                    iconSize={Icons.large}
                                    buttonStyle={
                                        this.props.contentData[2].uri ===
                                        this.state.selectedUri
                                            ? Buttons.buttonOptionSelected
                                            : Buttons.buttonOption
                                    }
                                    iconColor={
                                        this.props.contentData[2].uri ===
                                        this.state.selectedUri
                                            ? Colors.inverseText
                                            : Colors.primaryText
                                    }
                                />

                                <ButtonIcon
                                    onPress={() => {
                                        this.setSelectedUri(3)
                                    }}
                                    materialIcon={true}
                                    iconName={'numeric-4'}
                                    iconSize={Icons.large}
                                    buttonStyle={
                                        this.props.contentData[3].uri ===
                                        this.state.selectedUri
                                            ? Buttons.buttonOptionSelected
                                            : Buttons.buttonOption
                                    }
                                    iconColor={
                                        this.props.contentData[3].uri ===
                                        this.state.selectedUri
                                            ? Colors.inverseText
                                            : Colors.primaryText
                                    }
                                />
                            </View>
                        )}
                    </View>

                    <View style={Layout.columnTwo}>
                        {this.props.isImage
                            ? this.renderImage({
                                  uri: this.state.selectedUri,
                              })
                            : this.renderAppVideo({
                                  poster: this.state.selectedPoster,
                                  uri: this.state.selectedUri,
                              })}
                    </View>

                    {Layout.isMediumDevice ? (
                        <View
                            style={[
                                Layout.columnOne,
                                { paddingVertical: Layout.paddingSmall },
                            ]}
                        >
                            <View style={Layout.columnContainer}>
                                <ButtonIcon
                                    onPress={() => {
                                        this.setSelectedUri(0)
                                    }}
                                    materialIcon={true}
                                    iconName={'numeric-1'}
                                    iconSize={Icons.large}
                                    buttonStyle={
                                        this.props.contentData[0].uri ===
                                        this.state.selectedUri
                                            ? Buttons.buttonOptionSelected
                                            : Buttons.buttonOption
                                    }
                                    iconColor={
                                        this.props.contentData[0].uri ===
                                        this.state.selectedUri
                                            ? Colors.inverseText
                                            : Colors.primaryText
                                    }
                                />

                                <ButtonIcon
                                    onPress={() => {
                                        this.setSelectedUri(1)
                                    }}
                                    materialIcon={true}
                                    iconName={'numeric-2'}
                                    iconSize={Icons.large}
                                    buttonStyle={
                                        this.props.contentData[1].uri ===
                                        this.state.selectedUri
                                            ? Buttons.buttonOptionSelected
                                            : Buttons.buttonOption
                                    }
                                    iconColor={
                                        this.props.contentData[1].uri ===
                                        this.state.selectedUri
                                            ? Colors.inverseText
                                            : Colors.primaryText
                                    }
                                />

                                <ButtonIcon
                                    onPress={() => {
                                        this.setSelectedUri(2)
                                    }}
                                    materialIcon={true}
                                    iconName={'numeric-3'}
                                    iconSize={Icons.large}
                                    buttonStyle={
                                        this.props.contentData[2].uri ===
                                        this.state.selectedUri
                                            ? Buttons.buttonOptionSelected
                                            : Buttons.buttonOption
                                    }
                                    iconColor={
                                        this.props.contentData[2].uri ===
                                        this.state.selectedUri
                                            ? Colors.inverseText
                                            : Colors.primaryText
                                    }
                                />

                                <ButtonIcon
                                    onPress={() => {
                                        this.setSelectedUri(3)
                                    }}
                                    materialIcon={true}
                                    iconName={'numeric-4'}
                                    iconSize={Icons.large}
                                    buttonStyle={
                                        this.props.contentData[3].uri ===
                                        this.state.selectedUri
                                            ? Buttons.buttonOptionSelected
                                            : Buttons.buttonOption
                                    }
                                    iconColor={
                                        this.props.contentData[3].uri ===
                                        this.state.selectedUri
                                            ? Colors.inverseText
                                            : Colors.primaryText
                                    }
                                />
                            </View>
                        </View>
                    ) : (
                        <View />
                    )}
                </View>
            </View>
        )
    }
}
