import React from 'react'
import { ImageBackground, Image, ScrollView, Text, View } from 'react-native'
import { Foundation } from '@expo/vector-icons'
import Modal from 'react-native-modal'
import { Layout, Buttons, Colors, TextStyles, Icons } from '../../constants'
import contentData from '../../data/contentData'
import imageData from '../../data/imageData'
import MetaChartBubble from '../../components/MetaChartBubble'
import MetaWakeUp from '../../components/MetaWakeUp'
import ContentImageColumn from '../../components/ContentImageColumn'
import ImageCarousel from '../../components/ImageCarousel'
import MetaDesignFramework from '../../components/MetaDesignFramework'
import ProductVision from '../../components/ProductVision'
import ProductFeatures from '../../components/ProductFeaturesCarousel'
import ProductValues from '../../components/ProductValues'
import Button from '../../components/Button'
import TeamAttributes from '../../components/TeamAttributes'
import Footer from '../../components/Footer'
import MetaWakeUpHMW from '../../components/MetaWakeUpHMW'
import RequestInfo from '../../components/RequestInfo'

export default class MorphosisWear extends React.Component {
    state = {
        stickyIndex: null,
        stickyLocationOne: null,
        unStickyLocationOne: null,
        isModalVisible: false,
    }

    setStickyLocationOne = async (event) => {
        const pageLocation = await Math.floor(event.nativeEvent.layout.y)

        this.setState({ stickyLocationOne: pageLocation })
    }

    setUnStickyLocationOne = async (event) => {
        const pageLocation = await Math.floor(event.nativeEvent.layout.y)

        this.setState({ unStickyLocationOne: pageLocation })
    }

    onScroll = async (event) => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (
            pageYOffset >= this.state.stickyLocationOne &&
            pageYOffset <= this.state.unStickyLocationOne
        ) {
            this.setState({ stickyIndex: 15 }) // was 13, needs to correspond to number of components
        } else {
            this.setState({ stickyIndex: null })
        }
    }

    renderSeparator = () => {
        return (
            <View
                style={{
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: Layout.paddingMedium,
                    marginBottom: Layout.paddingMedium,
                }}
            />
        )
    }

    renderTimelineSeparator = (color) => {
        return (
            <View
                style={{
                    backgroundColor: color ? color : Colors.tintColor,
                    width: 2,
                    height: Layout.paddingLarge,
                    marginLeft: 18,
                    borderRadius: 8,
                    marginVertical: 4,
                }}
            />
        )
    }

    renderColumnSeparator = () => {
        return (
            <View
                style={{
                    backgroundColor: Colors.white,
                    width: Layout.isMediumDevice ? '100%' : 1,
                    height: Layout.isMediumDevice ? 1 : null,
                }}
            />
        )
    }

    render() {
        return (
            <ScrollView
                style={{ flex: 1, backgroundColor: '#fff' }}
                onScroll={(event) =>
                    Layout.isMediumDevice ? null : this.onScroll(event)
                }
                scrollEventThrottle={16}
                stickyHeaderIndices={[this.state.stickyIndex]}
                decelerationRate={'fast'}
                //indicatorStyle={'black'}
            >
                <Modal isVisible={this.state.isModalVisible}>
                    <View style={{ flex: 1 }}>
                        <RequestInfo
                            handlePress={() =>
                                this.setState({
                                    isModalVisible: !this.state.isModalVisible,
                                })
                            }
                        />
                        {/* <Button
                                // iconRight={true}
                                // iconName={'arrow-right'}
                                onPress={() =>
                                    this.setState({
                                        isModalVisible:
                                            !this.state.isModalVisible,
                                    })
                                }
                                buttonStyle={[
                                    Buttons.buttonOutlineInverse,
                                    {
                                        alignSelf: 'center',
                                    },
                                ]}
                                title={'Close me'}
                            /> */}
                    </View>
                </Modal>
                <View
                    style={[
                        Layout.projectContainer,
                        { backgroundColor: Colors.accentYellow },
                    ]}
                >
                    <View style={Layout.columnOne}>
                        <Text
                            style={[
                                TextStyles.Heading1,
                                {
                                    textAlign: 'center',
                                    maxWidth: 720,
                                },
                            ]}
                        >
                            adidas × Morphosis reimagines the possibilities of
                            performance wear, through the creative mind of
                            Dominique Crenn.
                        </Text>
                    </View>
                </View>

                <View style={Layout.sectionHeaderContainer}>
                    <View style={Layout.columnContainer}>
                        <View style={Layout.adidasVertical} />
                        <View style={Layout.adidasVertical} />
                        <View style={Layout.adidasVertical} />
                    </View>
                </View>

                <View style={[Layout.projectContainer, { paddingTop: 0 }]}>
                    <Image
                        source={require('../../assets/images/DC2.jpeg')}
                        style={[
                            Layout.heroImage,
                            { height: Layout.SCREEN_HEIGHT },
                        ]}
                        resizeMode={'cover'}
                    />
                </View>

                <View style={Layout.projectContainer}>
                    <View
                        style={[
                            Layout.columnContainer,
                            { flexDirection: 'row-reverse' },
                        ]}
                    >
                        <View style={Layout.columnTwo}>
                            <Text
                                style={[
                                    Layout.isMediumDevice
                                        ? TextStyles.Heading1
                                        : TextStyles.Heading1,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                The Morphosis Journey
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Heading3,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                Dominique Crenn, the acclaimed, Michelin-starred
                                chef is ready to partner with adidas to conceive
                                of a whole new vision for fashion. Her team has
                                modeled an overarching design system, crafted a
                                digital strategy, and generated original
                                concepts for shoes and apparel.
                            </Text>
                        </View>

                        <View style={Layout.columnTwo}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingBottom: Layout.paddingSmall,
                                }}
                            >
                                <Image
                                    source={require('../../assets/svgs/shop-like.svg')}
                                    style={{
                                        width: Icons.XL,
                                        height: Icons.XL,
                                        marginRight: Layout.paddingMedium,
                                    }}
                                    resizeMode={'cover'}
                                />
                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight:
                                                TextStyles.ListParagraphLineHeight,
                                        },
                                    ]}
                                >
                                    Maison Morphosis — creators of artifacts
                                    that inhabit both the digital and real world
                                </Text>
                            </View>
                            {this.renderTimelineSeparator()}

                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingBottom: Layout.paddingSmall,
                                }}
                            >
                                <Image
                                    source={require('../../assets/svgs/charging-light-idea.svg')}
                                    style={{
                                        width: Icons.XL,
                                        height: Icons.XL,
                                        marginRight: Layout.paddingMedium,
                                    }}
                                    resizeMode={'cover'}
                                />
                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight:
                                                TextStyles.ListParagraphLineHeight,
                                        },
                                    ]}
                                >
                                    Morphosis Wear — a vision for a head-to-toe
                                    fashion lineup that crosses digital
                                    boundaries
                                </Text>
                            </View>
                            {this.renderTimelineSeparator()}

                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingBottom: Layout.paddingSmall,
                                }}
                            >
                                <Image
                                    source={require('../../assets/svgs/award-star-head.svg')}
                                    style={{
                                        width: Icons.XL,
                                        height: Icons.XL,
                                        marginRight: Layout.paddingMedium,
                                    }}
                                    resizeMode={'cover'}
                                />
                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight:
                                                TextStyles.ListParagraphLineHeight,
                                        },
                                    ]}
                                >
                                    Morphosis Wear — design sketches for shoes,
                                    apparel, and accessories
                                </Text>
                            </View>
                            {this.renderTimelineSeparator(Colors.disabled)}

                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingBottom: Layout.paddingSmall,
                                }}
                            >
                                <Image
                                    source={require('../../assets/svgs/smartphone-payment-touch.svg')}
                                    style={{
                                        width: Icons.XL,
                                        height: Icons.XL,
                                        marginRight: Layout.paddingMedium,
                                    }}
                                    resizeMode={'cover'}
                                />
                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight:
                                                TextStyles.ListParagraphLineHeight,
                                        },
                                    ]}
                                >
                                    Morphosis Wear — digital drops for web3
                                    aficionados
                                </Text>
                            </View>

                            {this.renderTimelineSeparator(Colors.disabled)}

                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingBottom: Layout.paddingSmall,
                                }}
                            >
                                <Image
                                    source={require('../../assets/svgs/button-loop-arrow.svg')}
                                    style={{
                                        width: Icons.XL,
                                        height: Icons.XL,
                                        marginRight: Layout.paddingMedium,
                                        //opacity: 0.4,
                                    }}
                                    resizeMode={'cover'}
                                />
                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight:
                                                TextStyles.ListParagraphLineHeight,
                                        },
                                    ]}
                                >
                                    Made by Morphosis — products for the digital
                                    and real worlds
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={Layout.sectionHeaderContainer}>
                    <View style={Layout.columnContainer}>
                        <View style={Layout.adidasVertical} />
                        <View style={Layout.adidasVertical} />
                        <View style={Layout.adidasVertical} />
                    </View>
                </View>

                <View style={Layout.projectContainer}>
                    <View style={Layout.columnOne}>
                        <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    textAlign: 'center',
                                    paddingBottom: Layout.paddingLarge,
                                },
                            ]}
                        >
                            Insights
                        </Text>
                        <Text
                            style={[
                                TextStyles.Heading0,
                                {
                                    textAlign: 'center',
                                    paddingBottom: Layout.paddingXL,
                                },
                            ]}
                        >
                            Nurturing responsible growth
                        </Text>
                        <Text
                            style={[
                                TextStyles.Paragraph,
                                {
                                    maxWidth: 720,
                                    paddingBottom: Layout.paddingLarge,
                                },
                            ]}
                        >
                            One in three Americans got their first job
                            experience in a restaurant. The Food services
                            industry is expected to add 1.6 million jobs over
                            the next decade, with employment reaching 16.9
                            million by 2029. Restaurants provide vast
                            opportunities for work and hold great responsibility
                            to their employees, as well as their guests.
                        </Text>
                        <Text style={TextStyles.Caption}>
                            Source: National Restaurant Association Factbook
                        </Text>
                    </View>
                </View>

                <View style={Layout.projectContainer}>
                    <View style={Layout.columnContainer}>
                        <ImageBackground
                            style={Layout.columnThreeImages}
                            source={require('../../assets/images/hermes-rivera-594176-unsplash.jpg')}
                            resizeMode={'cover'}
                        >
                            <View style={Layout.contentOverlayImage}>
                                <Text
                                    style={[
                                        TextStyles.Heading2Inverse,
                                        {
                                            textAlign: 'center',
                                        },
                                    ]}
                                >
                                    Restaurants employ more teenagers than any
                                    other industry
                                </Text>
                            </View>
                        </ImageBackground>
                        {this.renderColumnSeparator()}

                        <ImageBackground
                            style={Layout.columnThreeImages}
                            source={require('../../assets/images/tanja-heffner-584869-unsplash.jpg')}
                            resizeMode={'cover'}
                        >
                            <View style={Layout.contentOverlayImage}>
                                <Text
                                    style={[
                                        TextStyles.Heading2Inverse,
                                        {
                                            textAlign: 'center',
                                        },
                                    ]}
                                >
                                    Restaurants employ more women managers than
                                    any other industry
                                </Text>
                            </View>
                        </ImageBackground>
                        {this.renderColumnSeparator()}

                        <ImageBackground
                            style={Layout.columnThreeImages}
                            source={require('../../assets/images/murilo-bahia-GxFw4_pKMN8-unsplash.jpg')}
                            resizeMode={'cover'}
                        >
                            <View style={Layout.contentOverlayImage}>
                                <Text
                                    style={[
                                        TextStyles.Heading2Inverse,
                                        {
                                            textAlign: 'center',
                                        },
                                    ]}
                                >
                                    Restaurants employ more minority managers
                                    than any other industry
                                </Text>
                            </View>
                        </ImageBackground>
                    </View>

                    <View style={Layout.basicContainer}>
                        <Text style={TextStyles.Caption}>
                            Source: National Restaurant Association Factbook
                        </Text>
                    </View>
                </View>

                {/* <View style={Layout.projectContainer}>
                        <View style={Layout.columnContainer}>
                            <View style={Layout.columnTwo}>
                                <Text
                                    style={[
                                        TextStyles.Heading2,
                                        {
                                            paddingBottom: Layout.paddingLarge,
                                            textAlign: 'center',
                                        },
                                    ]}
                                >
                                    While employment signals are strong, for the
                                    Food industry to flourish, it must address
                                    the problem of $162 billion in annual food
                                    waste.
                                </Text>
                                <Text
                                    style={[
                                        TextStyles.Caption,
                                        { textAlign: 'center' },
                                    ]}
                                >
                                    Source: USDA
                                </Text>
                            </View>

                            <View style={Layout.columnTwo}>
                                <Text
                                    style={[
                                        TextStyles.Heading2,
                                        {
                                            paddingBottom: Layout.paddingLarge,
                                            textAlign: 'center',
                                        },
                                    ]}
                                >
                                    Half of all dining guests say the
                                    availability of environmentally friendly
                                    food would make them choose one restaurant
                                    over another.
                                </Text>
                                <Text
                                    style={[
                                        TextStyles.Caption,
                                        { textAlign: 'center' },
                                    ]}
                                >
                                    Source: National Restaurant Association
                                    Factbook
                                </Text>
                            </View>
                        </View>
                    </View> */}

                <View style={Layout.sectionHeaderContainer}>
                    <View style={Layout.columnOne}>
                        <Text
                            style={[
                                TextStyles.Heading2,
                                {
                                    paddingBottom: Layout.paddingLarge,
                                    textAlign: 'center',
                                    maxWidth: 720,
                                },
                            ]}
                        >
                            While employment signals are strong, for the Food
                            industry to flourish, it must address the problem of
                            $162 billion in annual food waste.
                        </Text>
                        <Text
                            style={[
                                TextStyles.Caption,
                                { textAlign: 'center' },
                            ]}
                        >
                            Source: USDA
                        </Text>
                    </View>
                </View>

                <MetaWakeUp />

                <View style={Layout.sectionHeaderContainer}>
                    <View style={Layout.columnOne}>
                        <Text
                            style={[
                                TextStyles.Heading2,
                                {
                                    paddingBottom: Layout.paddingLarge,
                                    textAlign: 'center',
                                    maxWidth: 720,
                                },
                            ]}
                        >
                            Half of all dining guests say the availability of
                            environmentally friendly food would make them choose
                            one restaurant over another.
                        </Text>
                        <Text
                            style={[
                                TextStyles.Caption,
                                { textAlign: 'center' },
                            ]}
                        >
                            Source: National Restaurant Association Factbook
                        </Text>
                    </View>
                </View>

                <MetaChartBubble />

                <MetaWakeUpHMW />

                <View style={Layout.sectionHeaderContainer}>
                    <View style={Layout.columnContainer}>
                        <View style={Layout.adidasVertical} />
                        <View style={Layout.adidasVertical} />
                        <View style={Layout.adidasVertical} />
                    </View>
                </View>

                <View style={Layout.projectContainer}>
                    <View style={Layout.columnOne}>
                        <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    textAlign: 'center',
                                    paddingBottom: Layout.paddingLarge,
                                },
                            ]}
                        >
                            Inspiration
                        </Text>
                        <Text
                            style={[
                                TextStyles.Heading0,
                                {
                                    textAlign: 'center',
                                    paddingBottom: Layout.paddingXL,
                                },
                            ]}
                        >
                            Sustainable performance
                            {/* Drawing connections */}
                        </Text>
                        <Text
                            style={[
                                TextStyles.Paragraph,
                                {
                                    maxWidth: 720,
                                },
                            ]}
                        >
                            While responding to the food waste crisis in
                            restaurants, we stumbled upon opportunties to
                            improve other real world design challenges of the
                            people of Food & Hospitality. What if we applied
                            best practices in sustainability to transform the
                            industry beyond the food supply chain?
                        </Text>
                    </View>
                </View>

                <View
                    style={[
                        Layout.sectionContainer,
                        {
                            backgroundColor: 'transparent',
                        },
                    ]}
                    onLayout={(event) => this.setStickyLocationOne(event)}
                >
                    <View style={Layout.columnContainer}>
                        <View style={Layout.columnTwo}>
                            <Text
                                style={[
                                    TextStyles.Heading1,
                                    {
                                        textAlign: 'center',
                                    },
                                ]}
                            >
                                Metamorphosis{'\n'}of Fashion {'\n'}through Food
                            </Text>
                        </View>
                        {Layout.isMediumDevice ? (
                            <View />
                        ) : (
                            <View style={Layout.columnTwo} />
                        )}
                    </View>
                </View>

                <ContentImageColumn
                    uri={require('../../assets/images/dominique-crenn-AD-topbanner.jpg')}
                    align={'flex-end'}
                    title={'Inspired by the brilliant mind of Dominique Crenn'}
                    backgroundColor={'transparent'}
                />
                <ContentImageColumn
                    uri={require('../../assets/images/sasha-stories-XVTTK3wcYME-unsplash.jpg')}
                    align={'flex-start'}
                    title={'Insights from the people of Food and Hospitality'}
                    backgroundColor={'transparent'}
                />

                <ContentImageColumn
                    uri={require('../../assets/images/nursultan-rakysh-IunHchTIsM8-unsplash.jpg')}
                    align={'center'}
                    title={'Informed by real world design challenges'}
                    backgroundColor={'transparent'}
                />

                <ContentImageColumn
                    uri={require('../../assets/images/charles-kVg2DQTAK7c-unsplash.jpg')}
                    align={'flex-start'}
                    title={'Influenced by the creative orbit of Crenn’s world'}
                    backgroundColor={'transparent'}
                />
                <ContentImageColumn
                    uri={require('../../assets/images/mae-mu-rgRbqFweGF0-unsplash.jpg')}
                    align={'center'}
                    title={'Dedicated to people who are honing their craft'}
                    backgroundColor={'transparent'}
                />

                <ContentImageColumn
                    uri={require('../../assets/images/sai-de-silva-httxBNGKapo-unsplash.jpg')}
                    align={'flex-end'}
                    title={'Designed to meet the lifestyle needs of all people'}
                    backgroundColor={'transparent'}
                />

                <View
                    onLayout={(event) => this.setUnStickyLocationOne(event)}
                    style={[
                        Layout.sectionHeaderContainer,
                        {
                            minHeight: Layout.isMediumDevice ? 0 : 480,
                            height: Layout.isMediumDevice
                                ? 0
                                : Layout.SCREEN_HEIGHT * 0.618,
                        },
                    ]}
                />

                <View
                    style={[
                        Layout.sectionContainer,
                        { backgroundColor: Colors.accentYellow },
                    ]}
                >
                    <View style={Layout.columnOne}>
                        <Text
                            style={[
                                TextStyles.Heading1,
                                {
                                    textAlign: 'center',
                                    maxWidth: 720,
                                },
                            ]}
                        >
                            adidas × Morphosis, an inspired partnership at the
                            intersection of Food and Apparel
                        </Text>
                    </View>
                </View>

                <View style={Layout.sectionHeaderContainer}>
                    <View style={Layout.columnContainer}>
                        <View style={Layout.adidasVertical} />
                        <View style={Layout.adidasVertical} />
                        <View style={Layout.adidasVertical} />
                    </View>
                </View>

                <View style={Layout.projectContainer}>
                    <View style={Layout.columnOne}>
                        <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    textAlign: 'center',
                                    paddingBottom: Layout.paddingLarge,
                                },
                            ]}
                        >
                            Imagination
                        </Text>
                        <Text
                            style={[
                                TextStyles.Heading0,
                                {
                                    textAlign: 'center',
                                    paddingBottom: Layout.paddingXL,
                                },
                            ]}
                        >
                            Crafting design solutions
                        </Text>
                        <Text
                            style={[
                                TextStyles.Paragraph,
                                {
                                    maxWidth: 720,
                                },
                            ]}
                        >
                            Through a series of collaborative brainstorming
                            sessions, we went from ideation to implementation.
                            Inspired by our core values, we honed a vision,
                            harnessed a design framework and methodology, and
                            sketched a head-to-toe lineup.
                        </Text>
                    </View>
                </View>
                <ProductVision
                    title={
                        'adidas × Morphosis is a visionary fashion brand led by Dominique Crenn, dedicated to the next generation of Food Creatives'
                    }
                    uri={
                        Layout.isMediumDevice
                            ? require('../../assets/images/MetaVisionMobile.png')
                            : require('../../assets/images/MetaVision.png')
                    }
                />

                <MetaDesignFramework />

                <ProductFeatures
                    title={'Exploring dynamic intersections'}
                    contentData={contentData.metaDesignApproach}
                    isImage={true}
                />

                <ImageCarousel
                    title={'Footwear'}
                    imageData={imageData.footwear}
                    navigation={this.props.navigation}
                />
                <ImageCarousel
                    title={'Tops and Pants'}
                    imageData={imageData.apparel}
                    navigation={this.props.navigation}
                />
                <ImageCarousel
                    title={'Coats and Accessories'}
                    imageData={imageData.outerAndAccessories}
                    navigation={this.props.navigation}
                />
                <ProductValues contentData={contentData.metaValues} />

                <View style={Layout.projectContainer}>
                    <View
                        style={[
                            Layout.columnContainer,
                            {
                                alignItems: 'center',
                                flexDirection: Layout.isMediumDevice
                                    ? 'column-reverse'
                                    : 'row',
                            },
                        ]}
                    >
                        <View style={Layout.columnTwo}>
                            <Foundation
                                style={{
                                    paddingVertical: Layout.paddingMedium,
                                    alignSelf: 'flex-start',
                                }}
                                color={Colors.disabled}
                                size={Icons.XXL}
                                name={'quote'}
                            />
                            <Text
                                style={[
                                    Layout.isMediumDevice
                                        ? TextStyles.Heading3
                                        : TextStyles.Heading2,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                Every dish I create needs to come from a place
                                of purity, energy, and creativity. I feel most
                                creative and energetic when I am closest to the
                                earth. When I can feel grounded in my shoes and
                                confident enough to take risks.
                            </Text>
                            <Text style={TextStyles.Heading3}>
                                Dominique Crenn
                            </Text>
                            {/* <Text style={TextStyles.Paragraph}>
                                Visionary and Head of Morphosis
                            </Text> */}
                            <Text style={TextStyles.Overline}>
                                Restaurateur & Chef, Crenn Dining Group
                            </Text>
                            <Text style={TextStyles.Caption}>
                                San Franciso, USA
                            </Text>
                        </View>
                        <View style={Layout.columnTwo}>
                            <Image
                                source={require('../../assets/images/dominique-crenn-AD-6.jpg')}
                                style={{
                                    alignSelf: 'center',
                                    width: Layout.isMediumDevice
                                        ? '100%'
                                        : Layout.SCREEN_HEIGHT * 0.5,
                                    height: Layout.SCREEN_HEIGHT * 0.5,
                                }}
                                resizeMode={'cover'}
                            />
                        </View>
                    </View>
                </View>

                <TeamAttributes />

                <View style={Layout.sectionHeaderContainer}>
                    <View style={Layout.columnContainer}>
                        <View style={Layout.adidasVertical} />
                        <View style={Layout.adidasVertical} />
                        <View style={Layout.adidasVertical} />
                    </View>
                </View>

                <View
                    style={[
                        Layout.sectionHeaderContainer,
                        { backgroundColor: Colors.accentYellow },
                    ]}
                >
                    <View style={Layout.columnOne}>
                        <Text
                            style={[
                                TextStyles.Heading1,
                                { textAlign: 'center', maxWidth: 720 },
                            ]}
                        >
                            Together, adidas × Morphosis can transform an
                            industry and unite people through fashion.
                        </Text>
                    </View>
                </View>

                <ImageBackground
                    style={Layout.projectFooterContainer}
                    // source={require('../../assets/images/jakob-owens-xGw-mQb7Igs-unsplash.jpg')}
                    source={require('../../assets/images/tareq-ajalyakin-QGlntT8_57o-unsplash.jpg')}
                    resizeMode={'cover'}
                >
                    <View style={Layout.contentOverlay}>
                        <Button
                            onPress={() =>
                                this.props.navigation.navigate('PublicStack', {
                                    screen: 'RequestInfo',
                                })
                            }
                            // onPress={() =>
                            //     this.setState({
                            //         isModalVisible: !this.state.isModalVisible,
                            //     })
                            // }
                            buttonStyle={[
                                Buttons.buttonOutlineInverse,
                                {
                                    alignSelf: 'center',
                                },
                            ]}
                            title={'Get updates'}
                        />
                        {/* <Footer
                            style={{ alignSelf: 'flex-end' }}
                            navigation={this.props.navigation}
                        /> */}
                    </View>
                </ImageBackground>
            </ScrollView>
        )
    }
}
