import React from 'react'
import { Platform, View, TouchableOpacity, Text } from 'react-native'
import * as Linking from 'expo-linking'
import { Consumer } from '../context/Context'
import { Layout, TextStyles } from '../constants'

export default class AuthDrawerNavigation extends React.Component {
    render() {
        return (
            <Consumer>
                {(context) => {
                    return (
                        <View style={Layout.drawerContainer}>
                            <View
                                style={{
                                    flex: 1,
                                    paddingHorizontal: Layout.paddingLarge,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <TouchableOpacity
                                    onPress={() => {
                                        this.props.handlePress()

                                        if (Platform.OS == 'web') {
                                            window.open(
                                                'https://opensea.io/collection/morphosis-wear',
                                                '_blank'
                                            )
                                        } else {
                                            Linking.openURL(
                                                'https://opensea.io/collection/morphosis-wear'
                                            ) // normal Linking react-native
                                        }
                                    }}
                                >
                                    <Text
                                        style={[
                                            TextStyles.Heading2,
                                            {
                                                paddingBottom:
                                                    Layout.paddingLarge,
                                            },
                                        ]}
                                    >
                                        Opensea
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {
                                        this.props.handlePress()
                                        this.props.navigation.navigate(
                                            'PublicStack',
                                            {
                                                screen: 'RequestInfo',
                                            }
                                        )
                                    }}
                                >
                                    <Text
                                        style={[
                                            TextStyles.Heading2,
                                            {
                                                paddingBottom:
                                                    Layout.paddingLarge,
                                            },
                                        ]}
                                    >
                                        Follow
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {
                                        this.props.handlePress()
                                        this.props.navigation.navigate(
                                            'AuthAgreement'
                                        )
                                    }}
                                >
                                    <Text
                                        style={[
                                            TextStyles.Heading2,
                                            {
                                                paddingBottom:
                                                    Layout.paddingLarge,
                                            },
                                        ]}
                                    >
                                        Log in
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    )
                }}
            </Consumer>
        )
    }
}
