import React from 'react'
import { View, ImageBackground, Text } from 'react-native'
import { Layout, Colors, TextStyles } from '../constants'

export default class ContentImageColumn extends React.Component {
    render() {
        return (
            <View
                style={[
                    Layout.sectionContainer,
                    {
                        backgroundColor: 'transparent',
                        alignItems: 'flex-end',
                        borderBottomWidth: Layout.isMediumDevice ? 1 : 0,
                        //borderBottomWidth: 1,
                        borderBottomColor: Colors.white,
                    },
                ]}
            >
                <ImageBackground
                    style={Layout.columnTwoImages}
                    source={this.props.uri}
                    resizeMode={'cover'}
                >
                    <View style={Layout.contentOverlayImage}>
                        <Text
                            style={[
                                TextStyles.Heading2Inverse,
                                {
                                    textAlign: 'center',
                                    maxWidth: 480,
                                },
                            ]}
                        >
                            {this.props.title}
                        </Text>
                    </View>
                </ImageBackground>
            </View>
        )
    }
}
