const footwear = [
    {
        image: require('../assets/images/IMG_4383.jpg'),
    },
    {
        image: require('../assets/images/IMG_4373.jpg'),
    },
    {
        image: require('../assets/images/IMG_4391.jpg'),
    },
    {
        image: require('../assets/images/IMG_4393.jpg'),
    },
    {
        image: require('../assets/images/IMG_4385.jpg'),
    },
    {
        image: require('../assets/images/IMG_4379.jpg'),
    },
    {
        image: require('../assets/images/IMG_4387.jpg'),
    },
    {
        image: require('../assets/images/IMG_4381.jpg'),
    },
    {
        image: require('../assets/images/IMG_4394.jpg'),
    },
    {
        image: require('../assets/images/tareq-ajalyakin-hgs-WgmT8GA-unsplash.jpg'),
        blur: true,
    },
    {
        image: require('../assets/images/tareq-ajalyakin-hET6laBablI-unsplash.jpg'),
        blur: true,
    },
    {
        image: require('../assets/images/tareq-ajalyakin-QGlntT8_57o-unsplash.jpg'),
        blur: true,
    },
]

const apparel = [
    {
        image: require('../assets/images/IMG_4407.jpg'),
    },
    {
        image: require('../assets/images/IMG_4398.jpg'),
    },
    {
        image: require('../assets/images/IMG_4408.jpg'),
    },
    {
        image: require('../assets/images/IMG_4409.jpg'),
    },
    {
        image: require('../assets/images/IMG_4410.jpg'),
    },
    {
        image: require('../assets/images/IMG_4411.jpg'),
    },
    {
        image: require('../assets/images/IMG_4412.jpg'),
    },
    {
        image: require('../assets/images/IMG_4413.jpg'),
    },
    {
        image: require('../assets/images/IMG_4414.jpg'),
    },
    {
        image: require('../assets/images/tareq-ajalyakin-hgs-WgmT8GA-unsplash.jpg'),
        blur: true,
    },
    {
        image: require('../assets/images/tareq-ajalyakin-hET6laBablI-unsplash.jpg'),
        blur: true,
    },
    {
        image: require('../assets/images/tareq-ajalyakin-QGlntT8_57o-unsplash.jpg'),
        blur: true,
    },
]

const apparelOuter = [
    {
        image: require('../assets/images/IMG_4401.jpg'),
    },
    {
        image: require('../assets/images/IMG_4400.jpg'),
    },
    {
        image: require('../assets/images/IMG_4402.jpg'),
    },
    {
        image: require('../assets/images/IMG_4403.jpg'),
    },
    {
        image: require('../assets/images/IMG_4404.jpg'),
    },
    {
        image: require('../assets/images/IMG_4405.jpg'),
    },
    {
        image: require('../assets/images/IMG_4406.jpg'),
    },
]

const accessories = [
    {
        image: require('../assets/images/IMG_4421.jpg'),
    },
    {
        image: require('../assets/images/IMG_4418.jpg'),
    },

    {
        image: require('../assets/images/IMG_4423.jpg'),
    },
    {
        image: require('../assets/images/IMG_4420.jpg'),
    },
]

const outerAndAccessories = [
    {
        image: require('../assets/images/IMG_4401.jpg'),
    },
    {
        image: require('../assets/images/IMG_4421.jpg'),
    },
    {
        image: require('../assets/images/IMG_4406.jpg'),
    },

    {
        image: require('../assets/images/IMG_4402.jpg'),
    },
    {
        image: require('../assets/images/IMG_4418.jpg'),
    },
    {
        image: require('../assets/images/IMG_4403.jpg'),
    },

    {
        image: require('../assets/images/IMG_4404.jpg'),
    },
    {
        image: require('../assets/images/IMG_4420.jpg'),
    },
    {
        image: require('../assets/images/IMG_4405.jpg'),
    },
    {
        image: require('../assets/images/IMG_4419.jpg'),
    },
    {
        image: require('../assets/images/IMG_4399.jpg'),
    },
    {
        image: require('../assets/images/IMG_4422.jpg'),
    },
    {
        image: require('../assets/images/tareq-ajalyakin-hgs-WgmT8GA-unsplash.jpg'),
        blur: true,
    },
    {
        image: require('../assets/images/tareq-ajalyakin-hET6laBablI-unsplash.jpg'),
        blur: true,
    },
    {
        image: require('../assets/images/tareq-ajalyakin-QGlntT8_57o-unsplash.jpg'),
        blur: true,
    },
]

export default {
    footwear,
    apparel,
    apparelOuter,
    accessories,
    outerAndAccessories,
}
