import React from 'react'
import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native'
import { Buttons, Colors, Icons, TextStyles } from '../constants'
import { Feather } from '@expo/vector-icons'

export default class Button extends React.Component {
    render() {
        let style = [Buttons.button]
        if (this.props.disabled) {
            style.push(Buttons.disabledButton)
        }
        return (
            <TouchableOpacity
                style={[style, this.props.buttonStyle]}
                disabled={this.props.disabled}
                onPressIn={this.props.onPressIn}
                onPress={this.props.onPress}
                underlayColor={Colors.highlightColor}
            >
                {this.props.loading ? (
                    <ActivityIndicator
                        style={{
                            paddingHorizontal: Icons.large,
                        }}
                        size="small"
                        color={
                            this.props.activityColor
                                ? this.props.activityColor
                                : Colors.white
                        }
                    />
                ) : (
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {this.props.iconLeft ? (
                            <Feather
                                name={this.props.iconName}
                                style={[
                                    this.props.iconStyle,
                                    {
                                        paddingRight: 8,
                                        paddingBottom: 3,
                                    },
                                ]}
                                size={Icons.small}
                                color={
                                    this.props.disabled
                                        ? Colors.disabled
                                        : this.props.iconColor
                                        ? this.props.iconColor
                                        : Colors.inverseIcon
                                }
                            />
                        ) : (
                            <View />
                        )}
                        <View
                            style={{ paddingHorizontal: 4, paddingBottom: 3 }}
                        >
                            <Text
                                style={[
                                    TextStyles.Button,
                                    this.props.textStyle,
                                    {
                                        //paddingHorizontal: 4,
                                        paddingBottom: 3,
                                        color: this.props.disabled
                                            ? Colors.disabled
                                            : this.props.textColor
                                            ? this.props.textColor
                                            : Colors.inverseText,
                                    },
                                ]}
                            >
                                {this.props.title}
                            </Text>
                            {this.props.details ? (
                                <Text
                                    style={[
                                        TextStyles.Overline,
                                        this.props.textStyle,
                                        {
                                            //paddingHorizontal: 4,
                                            //paddingBottom: 3,
                                            color: this.props.disabled
                                                ? Colors.disabled
                                                : this.props.textColor
                                                ? this.props.textColor
                                                : Colors.inverseText,
                                        },
                                    ]}
                                >
                                    {this.props.details}
                                </Text>
                            ) : (
                                <View />
                            )}
                        </View>
                        {this.props.iconRight ? (
                            <Feather
                                name={this.props.iconName}
                                style={[
                                    this.props.iconStyle,
                                    {
                                        paddingLeft: 8,
                                        paddingBottom: 3,

                                        // color: this.props.disabled
                                        //     ? Colors.disabled
                                        //     : this.props.iconStyle
                                        //     ? this.props.iconStyle
                                        //     : Colors.inverseIcon,
                                    },
                                ]}
                                size={Icons.small}
                                color={
                                    this.props.disabled
                                        ? Colors.disabled
                                        : this.props.iconColor
                                        ? this.props.iconColor
                                        : Colors.inverseIcon
                                }
                            />
                        ) : (
                            <View />
                        )}
                    </View>
                )}
            </TouchableOpacity>
        )
    }
}
