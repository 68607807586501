import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
//import 'firebase/analytics'

//import DEV from './firebase.devSecrets'
import DEV from './firebase.secrets'
import PROD from './firebase.secrets'

/**
 *  Status: does not work in this workflow configuration.
 *
 *  SDK Auto-configuration
 *  https://firebase.google.com/docs/hosting/reserved-urls#sdk_auto-configuration
 *
 **/

// const config = fetch('/__/firebase/init.json').then(async response => {
//     firebase.initializeApp(await response.json())
// })
const devMode = __DEV__

const devConfig = {
    apiKey: DEV.apiKey,
    authDomain: DEV.authDomain,
    databaseURL: DEV.databaseURL,
    projectId: DEV.projectId,
    storageBucket: DEV.storageBucket,
    messagingSenderId: DEV.messagingSenderId,
}

const prodConfig = {
    apiKey: PROD.apiKey,
    authDomain: PROD.authDomain,
    databaseURL: PROD.databaseURL,
    projectId: PROD.projectId,
    storageBucket: PROD.storageBucket,
    messagingSenderId: PROD.messagingSenderId,
}

const config = devMode ? devConfig : prodConfig
/* Need to update firestore rules, and create indexes */
//const config = prodConfig

if (!firebase.apps.length) {
    firebase.initializeApp(config).firestore()
    //firebase.analytics()
}

export default config
