import React from 'react'
import { FlatList, TouchableOpacity, Image, Text, View } from 'react-native'
import { Icons, Colors, TextStyles, Layout } from '../constants'

export default class ProductValues extends React.Component {
    state = {
        selectedDescription: '',
    }

    componentDidMount = async () => {
        const contentData = await this.props.contentData

        if (contentData) {
            const selectedDescription = await contentData[0].description

            this.setState({
                selectedDescription: selectedDescription,
            })
        } else {
            return
        }
    }

    renderSeparator = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: Layout.paddingMedium,
                    marginBottom: Layout.paddingMedium,
                }}
            />
        )
    }

    renderMobileItem = ({ item }) => {
        return (
            <View
                style={{
                    flex: 1,
                }}
            >
                <View style={Layout.iconImageCircle}>
                    <Image
                        source={item.uri}
                        style={{
                            width: Icons.XL,
                            height: Icons.XL,
                        }}
                        resizeMode={'cover'}
                    />
                </View>

                <Text
                    style={[
                        TextStyles.Heading3,
                        { paddingBottom: Layout.paddingMedium },
                    ]}
                >
                    {item.label}
                </Text>

                <Text
                    style={[
                        TextStyles.Paragraph,
                        { paddingBottom: Layout.paddingLarge },
                    ]}
                >
                    {item.description}
                </Text>
            </View>
        )
    }

    renderDesktopItem = ({ item }) => {
        return (
            <TouchableOpacity
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
                onPress={() => {
                    this.setState({ selectedDescription: item.description })
                }}
            >
                <View
                    style={[
                        Layout.iconImageCircle,
                        {
                            backgroundColor:
                                item.description ===
                                this.state.selectedDescription
                                    ? Colors.accentYellow
                                    : Colors.gray0,
                        },
                    ]}
                >
                    <Image
                        source={
                            item.description === this.state.selectedDescription
                                ? item.uri
                                : item.uri
                        }
                        style={{
                            width: Icons.XL,
                            height: Icons.XL,
                        }}
                        resizeMode={'cover'}
                    />
                </View>

                <Text
                    style={[
                        TextStyles.Heading3,
                        { padding: Layout.paddingLarge },
                    ]}
                >
                    {item.label}
                </Text>
            </TouchableOpacity>
        )
    }

    render = () => {
        return (
            <View style={Layout.projectContainer}>
                <View style={Layout.columnOne}>
                    {Layout.isMediumDevice ? (
                        <FlatList
                            style={{ flex: 1 }}
                            data={this.props.contentData}
                            keyExtractor={item => item.label}
                            renderItem={this.renderMobileItem}
                            ItemSeparatorComponent={this.renderSeparator}
                        />
                    ) : (
                        <View
                            style={{
                                flex: 1,
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                            }}
                        >
                            <View style={Layout.columnTwo}>
                                <FlatList
                                    style={{
                                        flex: 1,
                                    }}
                                    data={this.props.contentData}
                                    keyExtractor={item => item.label}
                                    renderItem={this.renderDesktopItem}
                                />
                            </View>

                            <View style={Layout.columnTwo}>
                                <Text
                                    style={[
                                        TextStyles.Heading1,
                                        { textAlign: 'center' },
                                    ]}
                                >
                                    {this.state.selectedDescription}
                                </Text>
                            </View>
                        </View>
                    )}
                </View>
            </View>
        )
    }
}
