import React from 'react'
import { ImageBackground, Text, View } from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Colors, TextStyles, Layout, Icons } from '../constants'

export default class MetaDesignFramework extends React.Component {
    renderSeparator = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: Layout.paddingMedium,
                    marginBottom: Layout.paddingMedium,
                }}
            />
        )
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <ImageBackground
                    style={[Layout.projectContainer, { paddingVertical: 0 }]}
                    //source={require('../assets/images/annie-spratt-h61zjmw5zHo-unsplash.jpg')}
                    // source={require('../assets/images/philipp-arlt-U6nv62JWtxg-unsplash.jpg')}
                    source={require('../assets/images/filip-baotic-HjZ0qARrQDY-unsplash.jpg')}
                    resizeMode={'cover'}
                >
                    <View
                        style={[
                            Layout.contentOverlayImage,
                            {
                                paddingHorizontal: 0,
                                width: Layout.SCREEN_WIDTH,
                            },
                        ]}
                    >
                        <View style={Layout.columnOne}>
                            <View style={Layout.cardContent}>
                                <View
                                    style={[
                                        Layout.basicContainer,
                                        { paddingHorizontal: 0 },
                                    ]}
                                >
                                    <Text
                                        style={[
                                            TextStyles.Heading1,
                                            {
                                                textAlign: 'center',
                                            },
                                        ]}
                                    >
                                        Morphosis design framework
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        alignItems: 'flex-start',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <View
                                        style={[
                                            Layout.columnThree,
                                            {
                                                paddingHorizontal:
                                                    Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.paddingLarge,
                                            },
                                        ]}
                                    >
                                        <Text
                                            style={[
                                                TextStyles.Heading3,
                                                {
                                                    paddingBottom:
                                                        Layout.paddingLarge,
                                                },
                                            ]}
                                        >
                                            Metanarrative
                                        </Text>
                                        {/* <Text
                                        style={[
                                            TextStyles.Paragraph,
                                            {
                                                paddingBottom:
                                                    Layout.paddingLarge,
                                            },
                                        ]}
                                    >
                                        *** Brief description about this idea.
                                    </Text> */}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Brand Vision
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}

                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Brand Values
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Design Approach
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Humanity
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Seasonality
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Geography
                                            </Text>
                                        </View>
                                    </View>

                                    <View
                                        style={[
                                            Layout.columnThree,
                                            {
                                                paddingHorizontal:
                                                    Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.paddingLarge,
                                            },
                                        ]}
                                    >
                                        <Text
                                            style={[
                                                TextStyles.Heading3,
                                                {
                                                    paddingBottom:
                                                        Layout.paddingLarge,
                                                },
                                            ]}
                                        >
                                            Wearability
                                        </Text>
                                        {/* <Text
                                        style={[
                                            TextStyles.Paragraph,
                                            {
                                                paddingBottom:
                                                    Layout.paddingLarge,
                                            },
                                        ]}
                                    >
                                        *** Brief description about this idea.
                                    </Text> */}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Footwear
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Apparel
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Accessories
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Portability
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Connectivity
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Technology
                                            </Text>
                                        </View>
                                    </View>

                                    <View
                                        style={[
                                            Layout.columnThree,
                                            {
                                                paddingHorizontal:
                                                    Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.paddingLarge,
                                            },
                                        ]}
                                    >
                                        <Text
                                            style={[
                                                TextStyles.Heading3,
                                                {
                                                    paddingBottom:
                                                        Layout.paddingLarge,
                                                },
                                            ]}
                                        >
                                            Identity
                                        </Text>
                                        {/* <Text
                                        style={[
                                            TextStyles.Paragraph,
                                            {
                                                paddingBottom:
                                                    Layout.paddingLarge,
                                            },
                                        ]}
                                    >
                                        *** Brief description about this idea.
                                    </Text> */}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Materials
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}

                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Logomark
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Graphics
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Language and tone
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Packaging
                                            </Text>
                                        </View>
                                        {this.renderSeparator()}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                    paddingRight:
                                                        Layout.paddingSmall,
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.small}
                                                name={'plus'}
                                            />
                                            <Text style={TextStyles.Paragraph}>
                                                Messaging and voice
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </ImageBackground>
            </View>
        )
    }
}
