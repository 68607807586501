import React from 'react'
import { View, Text, ImageBackground } from 'react-native'
import { ResponsiveCirclePacking } from '@nivo/circle-packing'
import { Feather, Entypo, Foundation } from '@expo/vector-icons'
import { Layout, Colors, TextStyles, Icons } from '../constants'
import chartData from '../data/chartData'

export default class MetaChartBubble extends React.Component {
    state = {
        zoomedId: null,
    }

    render() {
        return (
            <View style={Layout.projectContainer}>
                <ImageBackground
                    style={Layout.chartBubbleContainer}
                    source={require('../assets/images/nick-karvounis-72018-unsplash.jpg')}
                    resizeMode={'cover'}
                >
                    <View
                        style={[
                            Layout.contentOverlayImage,
                            {
                                paddingHorizontal: 0,
                                width: Layout.SCREEN_WIDTH,
                            },
                        ]}
                    >
                        {/* Was ResponsiveBubble */}
                        <ResponsiveCirclePacking
                            data={chartData.metaBubble}
                            theme={{
                                tooltip: {
                                    container: {},
                                },
                                fontFamily: 'texgyreadventor-bold',
                                fontSize: 14,
                                textColor: Colors.secondaryText,
                                //lineHeight: 26,
                            }}
                            enableLabels={true}
                            margin={Layout.chartBubbleMargins}
                            id="name"
                            value="loc"
                            colors={[
                                Colors.accentYellow,
                                // Colors.matte,
                                // Colors.matte,
                                // Colors.matte,
                                // Colors.matte,
                                // Colors.matte,
                            ]}
                            colorBy="id"
                            padding={Layout.paddingMedium}
                            leavesOnly={true}
                            label={function (e) {
                                const label = e.id + ': ' + e.value + '%'

                                return label
                            }}
                            labelSkipRadius={0}
                            labelTextColor={Colors.tintColor}
                            borderColor={{ from: 'color', modifiers: [] }}
                            animate={true}
                            motionConfig={'stiff'}
                            zoomedId={this.state.zoomedId}
                            onClick={(node) => {
                                this.setState({
                                    zoomedId:
                                        this.state.zoomedId === node.id
                                            ? null
                                            : node.id,
                                })
                            }}
                            // tooltip={() => <View />}
                            tooltip={() => (
                                <Feather
                                    // style={{
                                    //     paddingRight: Layout.paddingSmall,
                                    // }}
                                    color={Colors.tintColor}
                                    size={Icons.large}
                                    name={'zoom-in'}
                                />
                            )}
                        />
                    </View>
                </ImageBackground>
                <View
                    style={[
                        Layout.basicContainer,
                        {
                            alignSelf: 'center',
                            flexDirection: 'row',
                        },
                    ]}
                >
                    <Feather
                        style={{
                            paddingRight: Layout.paddingSmall,
                        }}
                        color={Colors.secondaryIcon}
                        size={Icons.small}
                        name={'zoom-in'}
                    />
                    <Text style={TextStyles.Caption}>Tap item to zoom</Text>
                </View>

                <View style={Layout.columnContainer}>
                    <View style={Layout.columnTwo}>
                        <Text style={TextStyles.Overline}>Figure 5</Text>
                        <Text
                            style={[
                                TextStyles.Paragraph,
                                {
                                    paddingBottom: Layout.paddingSmall,
                                },
                            ]}
                        >
                            How restaurants reduce food waste
                        </Text>
                        <Text style={TextStyles.Caption}>
                            Source: Restaurant Success in 2019, Toast Inc.
                        </Text>
                    </View>
                    <View style={Layout.columnTwo}>
                        <Text style={TextStyles.Heading2}>
                            To reduce food waste, 38% of restaurant
                            professionals will use leftover ingredients from one
                            recipe in another.
                        </Text>
                    </View>
                </View>
            </View>
        )
    }
}
