import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import '../firebase.config'
import React, { useContext, useState, useEffect } from 'react'
import { View } from 'react-native'
import {
    NavigationContainer,
    DefaultTheme,
    DarkTheme,
} from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { ColorSchemeName } from 'react-native'

/* APIS, CONTEXT */
import { AuthContext } from '../context/AuthContext'
import { Context, Consumer } from '../context/Context'

import { Layout } from '../constants'

import MetaProjectScreen from '../screens/private/MorphosisWearScreen'
import RequestInfoScreen from '../screens/global/RequestInfoScreen'
import RequestConfirmationScreen from '../screens/global/RequestConfirmationScreen'
import UserAgreementScreen from '../screens/global/UserAgreementScreen'
import LogInScreen from '../screens/global/LogInScreen'
import AuthHomeScreen from '../screens/auth/AuthHomeScreen'
import AuthAgreementScreen from '../screens/auth/AuthAgreementScreen'

/* COMPONENTS */
import AuthLoading from '../components/AuthLoading'
import PrivateHeader from '../components/PrivateHeader'
import AuthHeader from '../components/AuthHeader'

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started

/**
 *
 * 2022
 * https://reactnavigation.org/docs/auth-flow/
 *
 * https://reactnavigation.org/docs/navigating-without-navigation-prop/#handling-initialization
 *
 * */

export default function Navigation({
    colorScheme,
}: {
    colorScheme: ColorSchemeName
}) {
    // /* Imported Auth stuff */
    const { user, setUser } = useContext(AuthContext)
    //const requestPushNotifications = useNotificationsPermission()

    const [loading, setLoading] = useState(true)
    const [initializing, setInitializing] = useState(true)

    function onAuthStateChanged(user) {
        setUser(user)
        if (initializing) setInitializing(false)
        setLoading(false)
    }

    useEffect(() => {
        const subscriber = firebase
            .auth()
            .onAuthStateChanged(onAuthStateChanged)
        return subscriber // unsubscribe on unmount
    }, [])

    if (loading) {
        return <AuthLoading />
    }

    return (
        <Consumer>
            {(value) => (
                <NavigationContainer
                    theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
                >
                    {/* {user ? <PrivateStackNavigator /> : <AuthStackNavigator />} */}

                    {user ? (
                        loading ? (
                            <AuthLoading />
                        ) : (
                            <PrivateStackNavigator />
                        )
                    ) : (
                        <AuthStackNavigator />
                    )}
                </NavigationContainer>
            )}
        </Consumer>
    )
}

const PrivateStack = createStackNavigator()

function PrivateStackNavigator() {
    return (
        <PrivateStack.Navigator
            initialRouteName="MorphosisWear"
            screenOptions={{
                title: 'Morphosis',
                //title: null,
                headerTransparent: true,
                //headerLeft: () => <View />,
                headerBackTitleVisible: false,
                //headerMode: Layout.isTabletDevice ? 'screen' : 'float', // 'float' | 'screen' | 'none'
                headerMode: 'float', // 'float' | 'screen' | 'none'
                headerStyle: {
                    elevation: 0, //for android
                    shadowOpacity: 0, //for ios
                    borderBottomWidth: 0, //for ios
                    height: Layout.isTabletDevice
                        ? Layout.topNavHeightMobile
                        : Layout.topNavHeightDesktop,
                },
            }}
        >
            <PrivateStack.Screen
                name="MorphosisWear"
                component={MetaProjectScreen}
                options={({ route, navigation }) => ({
                    headerTitle: '',
                    headerBackTitle: false,
                    headerBackTitleVisible: false,
                    headerLeft: (props) => (
                        <PrivateHeader
                            route={route}
                            navigation={navigation}
                            {...props}
                        />
                    ),
                })}
            />
            <PrivateStack.Screen
                name="PrivateHome"
                component={AuthHomeScreen}
                options={({ route, navigation }) => ({
                    headerTitle: '',
                    headerBackTitle: false,
                    headerBackTitleVisible: false,
                    headerLeft: (props) => (
                        <PrivateHeader
                            route={route}
                            navigation={navigation}
                            {...props}
                        />
                    ),
                })}
            />
            <PrivateStack.Screen
                name="PublicStack"
                component={PublicStackNavigator}
                options={({ route, navigation }) => ({
                    headerTitle: '',
                    headerBackTitle: false,
                    headerBackTitleVisible: false,
                    headerLeft: (props) => (
                        <PrivateHeader
                            route={route}
                            navigation={navigation}
                            {...props}
                        />
                    ),
                })}
            />
        </PrivateStack.Navigator>
    )
}

const AuthStack = createStackNavigator()

function AuthStackNavigator() {
    return (
        <AuthStack.Navigator
            initialRouteName="Home"
            screenOptions={{
                title: 'Morphosis',
                //title: null,
                headerTransparent: true,
                headerBackTitleVisible: false,
                //headerMode: Layout.isTabletDevice ? 'screen' : 'float', // 'float' | 'screen' | 'none'
                headerMode: 'float', // 'float' | 'screen' | 'none'
                headerStyle: {
                    elevation: 0, //for android
                    shadowOpacity: 0, //for ios
                    borderBottomWidth: 0, //for ios
                    height: Layout.isTabletDevice
                        ? Layout.topNavHeightMobile
                        : Layout.topNavHeightDesktop,
                },
            }}
        >
            <AuthStack.Screen
                name="AuthHome"
                component={AuthHomeScreen}
                options={({ route, navigation }) => ({
                    headerTitle: '',
                    headerBackTitle: false,
                    headerBackTitleVisible: false,
                    headerLeft: (props) => (
                        <AuthHeader
                            navigation={navigation}
                            route={route}
                            {...props}
                        />
                    ),
                })}
            />
            <AuthStack.Screen
                name="AuthAgreement"
                component={AuthAgreementScreen}
                options={({ route, navigation }) => ({
                    headerTitle: '',
                    headerBackTitle: false,
                    headerBackTitleVisible: false,
                    headerLeft: (props) => (
                        <AuthHeader
                            navigation={navigation}
                            route={route}
                            {...props}
                        />
                    ),
                })}
            />
            <AuthStack.Screen
                name="LogIn"
                component={LogInScreen}
                options={({ route, navigation }) => ({
                    headerTitle: '',
                    headerBackTitle: false,
                    headerBackTitleVisible: false,
                    headerLeft: (props) => (
                        <AuthHeader
                            navigation={navigation}
                            route={route}
                            {...props}
                        />
                    ),
                })}
            />
            <AuthStack.Screen
                name={'PublicStack'}
                component={PublicStackNavigator}
                options={({ route, navigation }) => ({
                    headerTitle: '',
                    headerBackTitle: false,
                    headerBackTitleVisible: false,
                    headerLeft: (props) => (
                        <AuthHeader
                            route={route}
                            navigation={navigation}
                            {...props}
                        />
                    ),
                })}
            />
        </AuthStack.Navigator>
    )
}

const PublicStack = createStackNavigator()

function PublicStackNavigator() {
    return (
        <PublicStack.Navigator
            screenOptions={{
                title: 'Morphosis',
                // title: null,
                headerTransparent: true,
                headerBackTitleVisible: false,
                //headerMode: Layout.isTabletDevice ? 'screen' : 'float', // 'float' | 'screen' | 'none'
                headerMode: 'float', // 'float' | 'screen' | 'none'
                headerStyle: {
                    elevation: 0, //for android
                    shadowOpacity: 0, //for ios
                    borderBottomWidth: 0, //for ios
                    height: Layout.isTabletDevice
                        ? Layout.topNavHeightMobile
                        : Layout.topNavHeightDesktop,
                },
            }}
        >
            <PublicStack.Screen
                name="UserAgreement"
                component={UserAgreementScreen}
                options={({ route, navigation }) => ({
                    headerTitle: '',
                    headerBackTitle: false,
                    headerBackTitleVisible: false,
                    headerLeft: () => <View />,
                })}
            />
            <PublicStack.Screen
                name="RequestInfo"
                component={RequestInfoScreen}
                options={({ route, navigation }) => ({
                    headerTitle: '',
                    headerBackTitle: false,
                    headerBackTitleVisible: false,
                    headerLeft: () => <View />,
                })}
            />
            <PublicStack.Screen
                name="RequestConfirmation"
                component={RequestConfirmationScreen}
                options={({ route, navigation }) => ({
                    headerTitle: '',
                    headerBackTitle: false,
                    headerBackTitleVisible: false,
                    headerLeft: () => <View />,
                })}
            />
        </PublicStack.Navigator>
    )
}
