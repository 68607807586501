import React from 'react'
import { ImageBackground, Text, View } from 'react-native'
import { Colors, TextStyles, Layout } from '../constants'

export default class ProductVision extends React.Component {
    render() {
        return (
            <View
                style={[
                    Layout.projectContainer,
                    { backgroundColor: Colors.white },
                ]}
            >
                <View
                    style={[Layout.columnContainer, { alignItems: 'center' }]}
                >
                    <View style={Layout.columnTwo}>
                        <Text
                            style={[
                                TextStyles.Heading1,
                                {
                                    textAlign: 'center',
                                },
                            ]}
                        >
                            {this.props.title}
                        </Text>
                    </View>

                    <View style={Layout.columnTwo}>
                        <ImageBackground
                            source={this.props.uri}
                            style={{
                                alignSelf: 'center',
                                width: '100%',
                                maxWidth: Layout.isMediumDevice ? 360 : null,
                                height: Layout.isMediumDevice
                                    ? Layout.SCREEN_HEIGHT
                                    : Layout.SCREEN_HEIGHT * 0.382,
                            }}
                            resizeMode={'contain'}
                        />
                    </View>
                </View>
            </View>
        )
    }
}
