import React from 'react'
import { ScrollView, Text, View } from 'react-native'
import { Feather } from '@expo/vector-icons'
import { StackActions } from '@react-navigation/native'
import { Buttons, Colors, Icons, TextStyles, Layout } from '../../constants'
import { Consumer } from '../../context/Context'
import Button from '../../components/Button'

export default class RequestConfirmation extends React.Component {
    componentDidMount() {
        this.context.resetUserForms()
    }

    render() {
        return (
            <Consumer>
                {(context) => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={(ref) => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                style={{
                                    flex: 1,
                                    //backgroundColor: Colors.gray0,
                                }}
                            >
                                <View
                                    style={[
                                        Layout.sectionContainer,
                                        {
                                            backgroundColor:
                                                Colors.accentYellow,
                                        },
                                    ]}
                                >
                                    <View style={Layout.columnOne}>
                                        <View
                                            style={{
                                                height: Icons.XL * 2,
                                                width: Icons.XL * 2,
                                                borderRadius: Icons.XL,
                                                borderWidth: 2,
                                                borderColor: Colors.tintColor,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                alignSelf: 'center',
                                                marginBottom:
                                                    Layout.paddingMedium,
                                            }}
                                        >
                                            <Feather
                                                style={{
                                                    alignSelf: 'center',
                                                }}
                                                color={Colors.tintColor}
                                                size={Icons.XL}
                                                name={'check'}
                                            />
                                        </View>
                                        <Text
                                            style={[
                                                TextStyles.Heading1,
                                                {
                                                    textAlign: 'center',
                                                    paddingBottom:
                                                        Layout.paddingMedium,
                                                },
                                            ]}
                                        >
                                            Thanks for signing up
                                        </Text>
                                        <Text
                                            style={[
                                                TextStyles.Paragraph,
                                                {
                                                    paddingBottom:
                                                        Layout.paddingLarge,
                                                    textAlign: 'center',
                                                },
                                            ]}
                                        >
                                            We’ll keep you updated on our
                                            journey.
                                        </Text>
                                        {/* <Button
                                            title={'Done'}
                                            buttonStyle={[
                                                Buttons.buttonMini,
                                                {
                                                    alignSelf: 'center',
                                                },
                                            ]}
                                            textColor={Colors.tintColor}
                                            // onPress={() => {
                                            //     this.props.navigation.popToTop()
                                            // }}
                                            onPress={() => {
                                                const popAction =
                                                    StackActions.pop(3)

                                                this.props.navigation.dispatch(
                                                    popAction
                                                )
                                            }}
                                        /> */}
                                    </View>
                                </View>
                            </ScrollView>
                        </View>
                    )
                }}
            </Consumer>
        )
    }
}
