import {
    Feather,
    MaterialCommunityIcons,
    MaterialIcons,
    FontAwesome,
    FontAwesome5,
} from '@expo/vector-icons'
import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import * as React from 'react'
import * as Updates from 'expo-updates'
import { Alert } from 'react-native'

export default function useCachedResources() {
    const [isLoadingComplete, setLoadingComplete] = React.useState(false)

    const devMode = __DEV__

    // Load any resources or data that we need prior to rendering the app
    React.useEffect(() => {
        async function loadResourcesAndDataAsync() {
            try {
                SplashScreen.preventAutoHideAsync()

                // Load fonts
                await Font.loadAsync({
                    ...Feather.font,
                    ...MaterialCommunityIcons.font,
                    ...MaterialIcons.font,
                    ...FontAwesome.font,
                    ...FontAwesome5.font,

                    'coconat-regular': require('../assets/fonts/Coconat-Regular.otf'),
                    'coconat-bold': require('../assets/fonts/Coconat-BoldExt.otf'),
                    'apfel-regular': require('../assets/fonts/ApfelGrotezk-Regular.otf'),
                    'violet-regular': require('../assets/fonts/VioletSans-Regular.otf'),
                    'texgyreadventor-bold': require('../assets/fonts/texgyreadventor-bold.otf'),
                    'texgyreadventor-bolditalic': require('../assets/fonts/texgyreadventor-bolditalic.otf'),
                    'texgyreadventor-italic': require('../assets/fonts/texgyreadventor-italic.otf'),
                    'texgyreadventor-regular': require('../assets/fonts/texgyreadventor-regular.otf'),
                })

                if (!devMode) {
                    const update = await Updates.checkForUpdateAsync()

                    if (update.isAvailable) {
                        await Updates.fetchUpdateAsync()
                        Alert.alert('Updating app')
                        Updates.reloadAsync()
                    } else {
                        return
                    }
                }
            } catch (e) {
                // We might want to provide this error information to an error reporting service
                console.warn(e)
            } finally {
                setLoadingComplete(true)
                SplashScreen.hideAsync()
            }
        }

        loadResourcesAndDataAsync()
    }, [])

    return isLoadingComplete
}
