import React from 'react'
import { Platform, View, TouchableOpacity, Text } from 'react-native'
import * as Linking from 'expo-linking'
import { Consumer } from '../context/Context'
import { Layout, Buttons, Colors, TextStyles } from '../constants'
import Button from './Button'

export default class PrivateDrawerNavigation extends React.Component {
    render() {
        return (
            <Consumer>
                {(context) => {
                    return (
                        <View style={Layout.drawerContainer}>
                            <View
                                style={{
                                    flex: 1,
                                    paddingHorizontal: Layout.paddingLarge,
                                    paddingTop: Layout.paddingXL,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <TouchableOpacity
                                    onPress={() => {
                                        this.props.handlePress()
                                        this.props.navigation.navigate(
                                            'MorphosisWear'
                                        )
                                    }}
                                >
                                    <Text
                                        style={[
                                            TextStyles.Heading2,
                                            {
                                                paddingBottom:
                                                    Layout.paddingLarge,
                                            },
                                        ]}
                                    >
                                        Vision
                                    </Text>
                                </TouchableOpacity>

                                <TouchableOpacity
                                    onPress={() => {
                                        this.props.handlePress()

                                        if (Platform.OS == 'web') {
                                            window.open(
                                                'https://opensea.io/collection/morphosis-wear',
                                                '_blank'
                                            )
                                        } else {
                                            Linking.openURL(
                                                'https://opensea.io/collection/morphosis-wear'
                                            ) // normal Linking react-native
                                        }
                                    }}
                                >
                                    <Text
                                        style={[
                                            TextStyles.Heading2,
                                            {
                                                paddingBottom:
                                                    Layout.paddingLarge,
                                            },
                                        ]}
                                    >
                                        Opensea
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {
                                        this.props.handlePress()
                                        this.props.navigation.navigate(
                                            'PublicStack',
                                            {
                                                screen: 'RequestInfo',
                                            }
                                        )
                                    }}
                                >
                                    <Text
                                        style={[
                                            TextStyles.Heading2,
                                            {
                                                paddingBottom:
                                                    Layout.paddingLarge,
                                            },
                                        ]}
                                    >
                                        Follow
                                    </Text>
                                </TouchableOpacity>
                            </View>

                            <View
                                style={{
                                    flexShrink: 1,
                                    //borderTopColor: Colors.lightBackground,
                                    //borderTopWidth: 1,
                                    padding: Layout.paddingLarge,
                                    alignItems: 'flex-start',
                                }}
                            >
                                <Button
                                    navigation={this.props.navigation}
                                    onPress={() => {
                                        this.props.handlePress()
                                        this.props.navigation.navigate(
                                            'PublicStack',
                                            {
                                                screen: 'UserAgreement',
                                            }
                                        )
                                    }}
                                    buttonStyle={[
                                        Buttons.buttonUnderline,
                                        { paddingHorizontal: 0 },
                                    ]}
                                    textColor={Colors.tintColor}
                                    textStyle={{
                                        textDecorationLine: 'underline',
                                    }}
                                    title={'Review agreement'}
                                />
                                <Button
                                    navigation={this.props.navigation}
                                    onPress={context.userSignOut}
                                    buttonStyle={[
                                        Buttons.buttonUnderline,
                                        { paddingHorizontal: 0 },
                                    ]}
                                    textColor={Colors.tintColor}
                                    textStyle={{
                                        textDecorationLine: 'underline',
                                    }}
                                    title={'Log out'}
                                />
                            </View>
                        </View>
                    )
                }}
            </Consumer>
        )
    }
}
