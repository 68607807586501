import React from 'react'
import { View, TextInput } from 'react-native'
import { Layout, TextStyles, Colors, Icons } from '../constants'
import { Consumer } from '../context/Context'
import { WindowResizeConsumer } from '../context/WindowResizeContext'
import ButtonIcon from './ButtonIcon'

export default class UserSignUp extends React.Component {
    state = {
        focused: false,
    }

    componentDidMount = async () => {
        //this.windowContext.setPreventResize()
        this.context.resetUserForms()
    }

    renderInputPassword = ({ password, secureEntry }) => {
        return (
            <Consumer>
                {(context) => {
                    return (
                        <View
                            style={{
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: Layout.isMediumDevice
                                    ? 'flex-start'
                                    : 'center',
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderBottomColor: this.state.focused
                                        ? Colors.tintColor
                                        : Colors.disabled,
                                    borderBottomWidth: 2,
                                }}
                            >
                                <TextInput
                                    style={[
                                        TextStyles.Heading0,
                                        {
                                            width: '100%',
                                            color: Colors.primaryText,
                                            outlineWidth: 0,
                                            paddingVertical: 16,
                                            paddingRight: 64,
                                            outline: 'none', // For Safari. This works, but creates a Warning

                                            // borderBottomColor: this.state
                                            //     .focused
                                            //     ? Colors.tintColor
                                            //     : Colors.disabled,
                                            //borderBottomWidth: 2,
                                        },
                                    ]}
                                    placeholder="Passcode"
                                    placeholderTextColor={Colors.disabled}
                                    selectionColor={Colors.tintColor}
                                    keyboardType="number-pad"
                                    value={password}
                                    onChangeText={(text) => {
                                        this.context.setPassword(text)
                                    }}
                                    onFocus={() => {
                                        this.setState({ focused: true })
                                    }}
                                    onSubmitEditing={async () =>
                                        await context
                                            .onLoginEmailPassword()
                                            .then(() =>
                                                context.resetUserForms()
                                            )
                                    }
                                    autoFocus={
                                        Layout.isMediumDevice ? true : false
                                    }
                                    maxLength={6}
                                    textContentType="password"
                                    secureTextEntry={secureEntry}
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    returnKeyType="done"
                                    blurOnSubmit={true}
                                    //autoFocus={false}
                                    //multiline={true}
                                    //disableFullscreenUI="true"
                                    //showSoftInputOnFocus={true}
                                />

                                <ButtonIcon
                                    iconName={'arrow-right'}
                                    iconSize={Icons.small}
                                    buttonStyle={{
                                        position: 'absolute',
                                        right: 0,
                                    }}
                                    disabled={!context.password.length}
                                    onPress={async () => {
                                        await context.onLoginEmailPassword()

                                        context.resetUserForms()
                                    }}
                                />
                            </View>
                        </View>
                    )
                }}
            </Consumer>
        )
    }

    render() {
        return (
            <WindowResizeConsumer>
                {(windowContext) => (
                    <View
                        style={{ flex: 1 }}
                        ref={(ref) => {
                            this.windowContext = windowContext
                        }}
                    >
                        <Consumer>
                            {(context) => {
                                return (
                                    <View
                                        style={{ flex: 1 }}
                                        ref={(ref) => {
                                            this.context = context
                                        }}
                                    >
                                        {this.renderInputPassword({
                                            sectionTitle: 'Password',
                                            password: context.password,
                                            secureEntry: context.secureEntry,
                                        })}
                                    </View>
                                )
                            }}
                        </Consumer>
                    </View>
                )}
            </WindowResizeConsumer>
        )
    }
}
