import Layout from './Layout'
import Colors from './Colors'

const regular = 'texgyreadventor-regular'
const bold = 'texgyreadventor-bold'

//const HeadingHeroLineHeight = Layout.isMediumDevice ? 112 : 164
//const HeadingHeroFontSize = Layout.isMediumDevice ? 104 : 156

const HeadingPoemLineHeight = Layout.isMediumDevice ? 112 : 164
const HeadingPoemFontSize = Layout.isMediumDevice ? 104 : 156

const HeadingHeroLineHeight = Layout.isMediumDevice
    ? Layout.SCREEN_WIDTH * 0.15 // was 0.125
    : Layout.SCREEN_WIDTH * 0.1
const HeadingHeroFontSize = Layout.isMediumDevice
    ? Layout.SCREEN_WIDTH * 0.15 // was 0.125
    : Layout.SCREEN_WIDTH * 0.1
const HeadingHeroLetterSpacing = Layout.isMediumDevice ? -1 : -4

const Heading0LineHeight = Layout.isMediumDevice ? 64 : 96
const Heading0FontSize = Layout.isMediumDevice ? 48 : 88

const Heading1LineHeight = Layout.isMediumDevice ? 44 : 52
const Heading1FontSize = Layout.isMediumDevice ? 34 : 44

const Heading2LineHeight = Layout.isMediumDevice ? 44 : 50
const Heading2FontSize = Layout.isMediumDevice ? 30 : 36

const Heading3LineHeight = 38
const SubtitleLineHeight = 42
const ParagraphLineHeight = 34
const ListParagraphLineHeight = 24
const CaptionLineHeight = 28

export default {
    ListParagraphLineHeight,

    LogotypeLarge: {
        fontFamily: 'violet-regular',
        fontSize: 32,
        color: Colors.primaryText,
        letterSpacing: -0.5,
        textTransform: 'uppercase',
    },
    Logotype: {
        fontFamily: 'violet-regular',
        fontSize: 24,
        color: Colors.primaryText,
        letterSpacing: -0.5,
        textTransform: 'uppercase',
    },
    HeadingHero: {
        fontFamily: bold,
        fontSize: HeadingHeroFontSize,
        //letterSpacing: -1.5,
        color: Colors.primaryText,
        lineHeight: HeadingHeroLineHeight,
        letterSpacing: HeadingHeroLetterSpacing,
        textTransform: 'uppercase',
        //wordWrap: 'wrap',
    },
    HeadingPoem: {
        fontFamily: bold,
        fontSize: HeadingPoemFontSize,
        letterSpacing: -1.5,
        color: Colors.primaryText,
        lineHeight: HeadingPoemLineHeight,
        textTransform: 'uppercase',
    },
    Heading0: {
        fontFamily: bold,
        fontSize: Heading0FontSize,
        letterSpacing: -1.5,
        color: Colors.primaryText,
        lineHeight: Heading0LineHeight,
    },
    Heading1: {
        fontFamily: bold,
        fontSize: Heading1FontSize,
        letterSpacing: -1,
        color: Colors.primaryText,
        lineHeight: Heading1LineHeight,
    },
    Heading2: {
        fontFamily: regular,
        fontSize: Heading2FontSize,
        letterSpacing: -0.5,
        color: Colors.primaryText,
        lineHeight: Heading2LineHeight,
    },
    Heading3: {
        fontFamily: regular,
        fontSize: 26, //22 // 21
        color: Colors.primaryText,
        letterSpacing: -0.25,
        lineHeight: Heading3LineHeight,
    },

    Subtitle: {
        fontFamily: bold,
        fontSize: 24,
        color: Colors.primaryText,
        lineHeight: SubtitleLineHeight,
    },
    Paragraph: {
        fontFamily: regular,
        fontSize: 20,
        color: Colors.primaryText,
        lineHeight: ParagraphLineHeight,
    },
    ParagraphBold: {
        fontFamily: bold,
        fontSize: 20,
        color: Colors.primaryText,
        lineHeight: ParagraphLineHeight,
    },

    Caption: {
        fontFamily: regular,
        fontSize: 16,
        color: Colors.secondaryText,
        letterSpacing: 0.5,
        lineHeight: CaptionLineHeight,
    },

    Overline: {
        fontFamily: bold,
        fontSize: 14,
        color: Colors.primaryText,
        letterSpacing: 2.5,
        textTransform: 'uppercase',
        lineHeight: CaptionLineHeight,
    },

    Button: {
        fontFamily: bold,
        fontSize: 16, //was 16
        letterSpacing: 0.25,
        color: Colors.primaryText,
    },

    // Inverse text with shadow
    LogotypeLargeInverse: {
        fontFamily: regular,
        fontSize: 32,
        color: Colors.inverseText,
        letterSpacing: -0.15,
    },

    LogotypeInverse: {
        fontFamily: regular,
        fontSize: 22,
        color: Colors.inverseText,
        letterSpacing: -0.15,
    },

    Heading0Inverse: {
        fontFamily: bold,
        fontSize: Heading0FontSize,
        letterSpacing: -1.5,
        color: Colors.inverseText,
        lineHeight: Heading0LineHeight,
    },

    Heading1Inverse: {
        fontFamily: bold,
        fontSize: Heading1FontSize,
        letterSpacing: -1,
        color: Colors.inverseText,
        lineHeight: Heading1LineHeight,
    },

    Heading2Inverse: {
        fontFamily: regular,
        fontSize: Heading2FontSize,
        letterSpacing: -0.5,
        color: Colors.inverseText,
        lineHeight: Heading2LineHeight,
    },

    Heading3Inverse: {
        fontFamily: regular,
        fontSize: 26, //21
        color: Colors.inverseText,
        letterSpacing: -0.25,
        lineHeight: Heading3LineHeight,
    },

    ParagraphInverse: {
        fontFamily: regular,
        fontSize: 16,
        color: Colors.inverseText,
        textTransform: 'uppercase',
    },

    ParagraphBoldInverse: {
        fontFamily: bold,
        fontSize: 16,
        color: Colors.inverseText,
        textTransform: 'uppercase',
    },

    SubtitleInverse: {
        fontFamily: bold,
        fontSize: 24,
        color: Colors.inverseText,
        lineHeight: SubtitleLineHeight,
    },
    ParagraphInverse: {
        fontFamily: regular,
        fontSize: 20,
        color: Colors.inverseText,
        lineHeight: ParagraphLineHeight,
    },
    CaptionInverse: {
        fontFamily: regular,
        fontSize: 16,
        color: Colors.inverseText,
        letterSpacing: 0.5,
        lineHeight: CaptionLineHeight,
    },
    OverlineInverse: {
        fontFamily: bold,
        fontSize: 14,
        color: Colors.inverseText,
        letterSpacing: 2.5,
        textTransform: 'uppercase',
        lineHeight: CaptionLineHeight,
    },
}
